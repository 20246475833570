export function ArrowBackButton() {
  window.history.back();
}
// Input date string
var inputDateString = "13-Dec-2023";

export function formatYY_MM_DD(inputDateString) {
  var inputDate = new Date(inputDateString);

  // Extract day, month, and year components
  var day = inputDate.getDate();
  var month = inputDate.getMonth() + 1;
  var year = inputDate.getFullYear() % 100;

  // Format day, month, and year with leading zeros if needed
  var formattedDay = (day < 10 ? "0" : "") + day;
  var formattedMonth = (month < 10 ? "0" : "") + month;
  var formattedYear = (year < 10 ? "0" : "") + year;

  // Create the formatted date string in "dd-mm-yy" format
  var formattedDateString =
    formattedYear + "-" + formattedMonth + "-" + formattedDay;

  return formattedDateString;
}

export function getDay(inputDateString) {
  // Input day of the month
  var inputDate = new Date(inputDateString);
  var dayOfMonth = inputDate.getDate();
  var currentDate = new Date();

  currentDate.setDate(dayOfMonth);

  var weekdayNumber = currentDate.getDay();

  var weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Get the weekday name based on the weekday number
  var weekdayName = weekdayNames[weekdayNumber];

  return weekdayName;
}

export function formatDate(date) {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  // Extracting day, month abbreviation, and year from the formatted date
  const [monthAbbr, day, year] = formattedDate.split(' ');

  // Mapping month abbreviation to its full name
  const monthMap = {
    Jan: 'Jan', Feb: 'Feb', Mar: 'Mar', Apr: 'Apr', May: 'May', Jun: 'Jun',
    Jul: 'Jul', Aug: 'Aug', Sep: 'Sep', Oct: 'Oct', Nov: 'Nov', Dec: 'Dec'
  };
  // Forming the final date string in "DD-MMM-YYYY" format
  const month = monthMap[monthAbbr];
  return `${day.trim()}-${month}-${year}`;  // Output: "14-Mar-2024"
}



// Create a Date object from the input string

export const handleNavigateSearch = (navigate) => {
  navigate("/search");
};

export function validateNumberInput(e) {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const handleNavigate = (navigate) => {
  navigate("/cart/diagnostictest");
};

export function validateTextInput(input) {
  input.value = input.value.replace(/[^a-zA-Z ]/g, '');
}

export const fetchApi = async (
  Url,
  requestOptions,
  setData,
  setLoading,
  setErrorData
) => {
  try {
    const response = await fetch(Url, requestOptions);
    const res = await response.json();
    if (res.status) {
      setData(res);
      setLoading(false);
      localStorage.setItem("broker_id" , JSON.stringify(res?.data?.corporate_data?.broker_id));
    } else {
      setLoading(false);
    }
  } catch (error) {   
    console.log(error);
    setErrorData(true);
  }
};

// Date format in YY_MM_DD
export const format_yymmdd_date = (date) => {
  if (!date) return '';
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};