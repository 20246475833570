import { React, useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBack, appCartIcon, headerLogo } from "../../../images";
import { handleNavigate, fetchApi, ArrowBackButton } from "../../../utils/AllFunction";
import { IoIosSearch } from "react-icons/io";
import { AppContext } from "../../../ContextApi";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import SymptomsCard from "../Components/SymptomsCard";
import { TELECONSULTATION_LIST_API, REQUESTOPTIONS } from '../../../Constants'

const SymptomsTeleConsultation = (props) => {
  const navigate = useNavigate();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [isErrorData, setErrorData] = useState([]);
  const [teleconsultationData, setTeleConsultationData] = useState([]);
  const [isDataLoading, setDataLoading] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchquery, setSearchQuery] = useState("");

  const token = localStorage.getItem("ACCESS_TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  useEffect(() => {
    fetchApi(
      TELECONSULTATION_LIST_API,
      requestOptions,
      setTeleConsultationData,
      setDataLoading,
      setErrorData
    );
  }, []);

  const handleSearch = (e, teleconsultationData) => {
    const searchquery = e.target.value.toLowerCase();

    if (teleconsultationData?.tele_departments?.symptoms.length > 0) {
      const searchedData =
        teleconsultationData?.tele_departments?.symptoms.filter((teleData) =>
          teleData.department_name.toLowerCase().includes(searchquery)
        );

      setFilteredData(searchedData);
      setSearchQuery(searchquery);
    } else {
      setFilteredData(null);
    }
  };


  const handleBooking = (data , type) =>{
    localStorage.setItem('BOOKED_TELECONSULTATION_DATA' , JSON.stringify(data));
    localStorage.setItem("mbConsultType" , JSON.stringify("speciality"))
    if(type !== ""){
      navigate(`/book-teleconsultation?type=${type}`)
    }
    else{
      navigate("/book-teleconsultation");
    }
  }

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Teleconsultation</h6>
            </div>
          </div>
        </div>
        <div className="app-searh-section">
          <div className="inputWithIcon input-tele-search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e, teleconsultationData)}
            />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="symptoms-main-wrapper-cover px-3 mt-4">
          <div className="symptoms-header mb-3">
            <h6>Symptoms</h6>
          </div>

          <div className="symptoms-product-main-wrapper d-flex justify-content-between">
            {isDataLoading ? (
              <Loader />
            ) : (
              <>
                {searchquery.length > 0 ? (
                  <div className="specialist-product-box d-flex justify-content-between">
                    {filteredData && filteredData.length > 0 ? (
                      filteredData.map((specialityData) => (
                        <SymptomsCard
                          key={specialityData.id}
                          data={specialityData}
                          imgPath={teleconsultationData?.img_path}
                          handleBooking={() => handleBooking(specialityData , specialityData.is_free)}
                        />
                      ))
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                ) : (
                  <div className="specialist-product-box d-flex justify-content-between">
                    {teleconsultationData?.tele_departments?.symptoms?.length >
                    0 ? (
                      teleconsultationData?.tele_departments?.symptoms.map(
                        (symptomsData) => {
                          return (
                            <SymptomsCard
                              data={symptomsData}
                              handleBooking={() => handleBooking(symptomsData , symptomsData.is_free)}
                              imgPath={teleconsultationData?.img_path}
                            />
                          );
                        }
                      )
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SymptomsTeleConsultation;
