import React, { useEffect } from "react";
import ComingSoon from "../../Animation/ComingSoon";
import { Link, NavLink } from "react-router-dom";
import {ArrowBack, appCart, appNotification,headerLogo} from "../../images";

const HomeRadiology = () => {
  const ArrowBackButton = () => {
    window.history.back();
  };
  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>Radiology</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <a href="#">
                <img src={appNotification} width="100%" alt="notification" />
              </a>
            </div>
            <div className="cart-icon">
              <NavLink to="/cart">
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
            </div>
          </div>
        </div>
        <ComingSoon/>
      </div>
    </>
  );
};

export default HomeRadiology;
