import React from 'react'
import { DataNotfound } from '../images';


const NoDataFoundComp = () => {
  return (
    <div className='nodata-found-main-wrapper'>
        <div className='noData-img-box'>
            <img src={DataNotfound} alt='no-data'></img>
        </div>
    </div>
  )
}

export default NoDataFoundComp