import React from "react";
import { ArrowBack } from "../../../images";
import { ArrowBackButton } from "../../../utils/AllFunction";
import { NavLink } from "react-router-dom";

const ProfileHeader = ({Heading}) => {
  return (
    <>
      <div className="profile-banner profile-header">
          <div className="back-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="prescription-heading">
            <h5 className="text-center">{Heading}</h5>
          </div>
        </div>
    </>
  );
};

export default ProfileHeader;
