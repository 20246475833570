// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import DeleteLoader from "../AnimationJson/delete-loader.json";
import Modal from 'react-bootstrap/Modal';

const Deleteloader = (props) => {
    const {successMessage  } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Lottie animationData={DeleteLoader} className='success-data-animation' />
                {successMessage && <p className='error-msg'>{successMessage}</p>
                }
            </Modal.Body>
        </Modal>
    )
}

export default Deleteloader;
