import React, { useState, useContext, useEffect } from "react";
import { NavLink, useParams , useNavigate } from "react-router-dom";
import { ArrowBackButton , handleNavigateSearch} from "../../utils/AllFunction";
import { TEST_BY_CATEGORY_API } from "../../Constants";
import Loader from "../../Animation/Loader";
import GotoCart from "../Cart/GotoCart";
import NoDataFoundComp from "../../components/NoDataFound";
import {
  ArrowBack,
  appCartIcon,
  appCommonlyBookTest,
  appHeartIcon,
} from "../../images";
import Modals from "../Modals";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { Toaster, toast } from "react-hot-toast";

const TestbyCategories = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [testCategories, setTestCategories] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const { category_id, category_name } = useParams();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const navigate = useNavigate();

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  useEffect(() => {
    const GetTestCategories = async () => {
      setDataLoading(true);
      try {
        const apiUrl = `${TEST_BY_CATEGORY_API}?category=${category_id}`;
        const response = await fetch(apiUrl, requestOptions);

        const res = await response.json();
        if (res.status) {
          setTestCategories(res.data);
          setDataLoading(false);
          console.log(res.data);
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    GetTestCategories();
  }, []);

  const handleCart = (id) => {
    const isItemInCart = CartData.some((item) => item.test_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...testCategories.filter((data) => data.test_id === id),
      ]);
      // toast.success("Item Added")
    } else {
      const updatedCart = CartData.filter((item) => item.test_id !== id);
      setCartData(updatedCart);
      // toast.success("Item Removed Succesfully")
    }
  };

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);

  return (
    <>
      <div className="home-review-banner">
        <div className={`${testCategories.length >= 2 ? "add-mx-height" : ""} lab-book-categories`} >
          <div className="app-header-wraper">
            <div className="header-navigation-icon">
              <NavLink onClick={ArrowBackButton}>
                <img src={ArrowBack} width="100%" alt="arrow-back" />
              </NavLink>
            </div>
            <div className="header-top-section">
              <div className="header-top-section-category-section">
                <h3>{category_name}</h3>
                <Modals show={modalShow} onHide={() => setModalShow(false)} />
              </div>
              <div className="header-top-section-add-to-cart-section">
                <button className="">
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  <span className="Header-count-number">{CartData.length > 0 ? CartData.length : 0}</span>
                </button>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          </div>

          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input type="text" placeholder="Search" onClick={() =>handleNavigateSearch(navigate)}/>
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="Test-categories-content-wrapper-box">
            <div className="container">
              <div className="row d-flex mb-4">
                {isDataLoading ? (
                  <Loader />
                ) : testCategories.length > 0 && (
                  testCategories.map((test, elem) => (
                    <div className="col-6">
                      <div className="common-test-box mb-3">
                        <NavLink to={`/test-description/${test.test_id}`}>
                          <div className="common-test-image">
                            <img
                              src={appCommonlyBookTest}
                              width="100%"
                              alt="Book-test"
                            />
                          </div>
                          <div className="offer-common">
                            <p>{test?.discount_percent} OFF</p>
                          </div>
                          <div className="common-test-heading">
                            <h6>{test.test_heading}</h6>
                          </div>
                          <hr />
                          <div className="common-test-content">
                            <p>
                              Included Tests :{" "}
                              {test.test_parameter.length > 0 &&
                                test.test_parameter.length}
                            </p>
                            <h6>Medibhai Price : ₹ {test?.test_medi_price}</h6>
                            <span>MRP: ₹ {test?.test_mrp}</span>
                          </div>
                        </NavLink>

                        {CartData.some(
                          (item) => item.test_id === test.test_id
                        ) ? (
                          <button
                            className="remove-cart-btn"
                            onClick={() => handleCart(test.test_id)}
                          >
                            Remove
                          </button>
                        ) : (
                          <div className="common-add-cart">
                            <button onClick={() => handleCart(test.test_id)}>
                              <p>ADD TO CART</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
      {CartData.length > 0 && <GotoCart link="diagnostictest" CartData={CartData} />}
        </div>
      </div>
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 1800,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
    </>
  );
};

export default TestbyCategories;
