import { React, useEffect, useState, useContext } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  headerLogo,
  physician_icon,
  ArrowBack,
  WorkPointIcon1,
  WorkPointIcon2,
  WorkPointIcon3,
  WorkPointIcon4,
  WorkPointIcon5,
  howitworknum1,
  howitworknum2,
  howitworknum3,
  howitworknum4,
  howitworknum5,
} from "../../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  TELECONSULTATION_LIST_API,
  REQUESTOPTIONS,
  BROKER_EMEDLIFE,
  BROKER_ODIN,
  BROKER_ICARE,
} from "../../../Constants";
import { fetchApi } from "../../../utils/AllFunction";
import { IoIosSearch } from "react-icons/io";
import { TbChevronRight } from "react-icons/tb";
import { AppContext } from "../../../ContextApi";
import SpecialityProduct from "../Components/SpecialityProduct";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import SymptomsCard from "../Components/SymptomsCard";


const HomeTeleConsultation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dep_id = queryParams.get("dept_id");
  const v2href = queryParams.get("href");
  const V2token = queryParams.get("token");
  const { brokerId } = useContext(AppContext);

  const [isErrorData, setErrorData] = useState([]);
  const [teleconsultationData, setTeleConsultationData] = useState([]);
  const [isDataLoading, setDataLoading] = useState();
  const token = localStorage.getItem("ACCESS_TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  useEffect(() => {
    fetchApi(
      TELECONSULTATION_LIST_API,
      requestOptions,
      setTeleConsultationData,
      setDataLoading,
      setErrorData
    );
  }, []);

  const handleBooking = (data, type) => {
    console.log(data)
    localStorage.setItem("BOOKED_TELECONSULTATION_DATA", JSON.stringify(data));
    if (type !== "") {
      navigate(`/book-teleconsultation?type=${type}`);
    } else {
      navigate("/book-teleconsultation");
    }
  };

  useEffect(() => {
    const data = {
      dept_id: 10,
      department_name: "Dietitian",
      department_image: "nutrition-and-dietetics_2024-01-30-16-51-26.png",
      department_descriptoon:
        "Diet and nutrition counseling and coaching for healthy eating, weight management, and chronic illness dietary management.",
      doctor_id: 13,
      doctor_name: "Dr Reshma",
      doctor_mobile: 9819412221,
      doctor_email: "info@medibhai.com",
      cosult_charge: 249, // Corrected property name
      created_on: "2024-01-30 16:51:26",
      is_free: 1,
      min_date_time: "2024-03-24",
      max_date_time: "2024-03-30",
    };
    localStorage.setItem("BOOKED_TELECONSULTATION_DATA", JSON.stringify(data));

    if (props.isV2token && dep_id !== null) {
      setTimeout(() => {
        navigate(
          `/book-teleconsultation?v2Token=${props.isV2token}&href=${v2href}&dept_id=${dep_id}`
        );
      }, 500);
    } else {
      console.log("NOt Found");
    }
  }, []);

  const handleNavigation = () => {
    props.token ? window.history.go(-2) : window.history.back();
  };

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-navigation-icon">
            <h5 onClick={handleNavigation}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </h5>
          </div>
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Teleconsultation</h6>
            </div>
          </div>
        </div>
        {/* <div className="app-searh-section">
          <div
            className="inputWithIcon input-tele-search"
            onClick={() => handleNavigateSearch(navigate)}
          >
            <input type="text" placeholder="Search" />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div> */}

        {teleconsultationData?.tele_departments?.free_teleconsultation.length > 0 && (
          <div
            className="home-teleconsultation-free-teleconsultation-cover"
            onClick={() =>
              handleBooking(
                teleconsultationData?.tele_departments
                  ?.free_teleconsultation[0],
                teleconsultationData?.tele_departments?.free_teleconsultation[0].is_free == 1 ? "1" : "0"
              )
            }
          >


            <div className="free-consultation d-flex align-items-center">

              <div className="consultation-icon-box">
                <img src={physician_icon} alt="physician icon"></img>
              </div>
              <div className="consultation-content-box">
                <h5>
                  {
                    teleconsultationData?.tele_departments
                      ?.free_teleconsultation[0]?.department_name
                  }
                </h5>
                <p>Consultation</p>
              </div>
            </div>
          </div>
        )}

        <div className="home-teleconsultation-main-wrapper mt-4 px-2">
          <div className="specialist-doctor-home-wrapper">
            <div className="specialist-header d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Specialist Doctors</h6>
              <NavLink
                className="view-all-specialist"
                to="/specialist-teleconsultation"
              >
                <button>
                  View All{" "}
                  <span>
                    <TbChevronRight />
                  </span>
                </button>
              </NavLink>
            </div>
            <div className="specialist-product-main-wrapper mt-3 px-1">
              {isDataLoading ? (
                <Loader />
              ) : (
                <div className="specialist-product-box d-flex justify-content-between">
                  {teleconsultationData?.tele_departments?.teleconsultaton
                    ?.length > 0 ? (
                    teleconsultationData?.tele_departments?.teleconsultaton
                      .slice(0, 4)
                      .map((specialityData) => {
                        return <SpecialityProduct data={specialityData} imgPath={teleconsultationData?.img_path} handleBooking={() => handleBooking(specialityData, specialityData?.is_free)} />;
                      })
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="home-teleconsultation-banner-wrapper">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.2}
              loop="true"
              //   autoplay={{ delay: 1000, disableoninteraction: false }}
              speed={2000}
              effect="slide"
              grabCursor={true}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
            //   modules={[Autoplay]}
            >
              <div className="swiper-wrapper">
                {teleconsultationData?.tele_departments?.teleconsultation_banners.map(
                  (tele_banners) => {
                    return (
                      <SwiperSlide
                        className="applabtest-repo"
                        key={tele_banners.id}
                      >
                        <NavLink to="/">
                          <div className="teleconsultation-banner-box">
                            <img src={tele_banners.image}></img>
                          </div>
                        </NavLink>
                      </SwiperSlide>
                    );
                  }
                )}
              </div>
            </Swiper>
          </div>
        </div>
        <div className="symptoms-main-wrapper-cover px-3 mt-3">
          <div className="symptoms-header mb-2">
            <h6>Symptoms</h6>
          </div>
          <div className="symptoms-product-main-wrapper d-flex justify-content-between">
            {isDataLoading ? (
              <Loader />
            ) : (
              <div className="specialist-product-box d-flex justify-content-between">
                {teleconsultationData?.tele_departments?.symptoms?.length >
                  0 ? (
                  teleconsultationData?.tele_departments?.symptoms
                    .slice(0, 3)
                    .map((symptomsData) => {
                      return (
                        <SymptomsCard
                          data={symptomsData}
                          imgPath={teleconsultationData?.img_path}
                          handleBooking={() => handleBooking(symptomsData, symptomsData.is_free)}
                        />
                      );
                    })
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            )}
          </div>
          <div className="symptoms-footer d-flex justify-content-center mt-3">
            <NavLink
              className="view-all-specialist"
              to="/symptoms-teleconsultation"
            >
              <button className="symptoms-view-button">
                View All{" "}
                <span>
                  <TbChevronRight />
                </span>
              </button>
            </NavLink>
          </div>
          <div className="how-it-works-main-wrapper mt-4">
            <div className="how-it-works-main-content">
              <div className="how-it-works-header">
                <h6>How It Works?</h6>
                <p>
                  We aim to extend our doctor consultation services to remote
                  location via Teleconsultation.
                </p>
              </div>
              <div className="how-it-works-points-box">
                <div className="how-it-works-point my-3">
                  <div className="title-detail-box d-flex align-items-center">
                    <img src={WorkPointIcon1} alt="how-it-works-icon"></img>
                    <div className="title-content">
                      <h6>Select Specility</h6>
                      <p>Select the specialty or symptoms</p>
                    </div>
                  </div>
                  <img
                    src={howitworknum1}
                    className="numbering-img"
                    alt="WorkPointIcon"
                  ></img>
                </div>
                <div className="how-it-works-point my-3">
                  <div className="title-detail-box d-flex align-items-center">
                    <img src={WorkPointIcon2} alt="how-it-works-icon"></img>
                    <div className="title-content">
                      <h6>Share Details</h6>
                      <p>Share the patient name and make the payment</p>
                    </div>
                  </div>
                  <img
                    src={howitworknum2}
                    className="numbering-img"
                    alt="WorkPointIcon"
                  ></img>
                </div>
                <div className="how-it-works-point my-3">
                  <div className="title-detail-box d-flex align-items-center">
                    <img src={WorkPointIcon3} alt="how-it-works-icon"></img>
                    <div className="title-content">
                      <h6>Receive Link</h6>
                      <p>You will receive a link for Video consultation</p>
                    </div>
                  </div>
                  <img
                    src={howitworknum3}
                    className="numbering-img"
                    alt="WorkPointIcon"
                  ></img>
                </div>
                <div className="how-it-works-point my-3">
                  <div className="title-detail-box d-flex align-items-center">
                    <img src={WorkPointIcon4} alt="how-it-works-icon"></img>
                    <div className="title-content">
                      <h6>Join Consultation</h6>
                      <p>Join the link and consult with specialist Doctor</p>
                    </div>
                  </div>
                  <img
                    src={howitworknum4}
                    className="numbering-img"
                    alt="WorkPointIcon"
                  ></img>
                </div>
                <div className="how-it-works-point my-3">
                  <div className="title-detail-box d-flex align-items-center">
                    <img src={WorkPointIcon5} alt="how-it-works-icon"></img>
                    <div className="title-content">
                      <h6>Get Prescription</h6>
                      <p>
                        You will receive the signed prescription soon after the
                        call
                      </p>
                    </div>
                  </div>
                  <img
                    src={howitworknum5}
                    className="numbering-img"
                    alt="WorkPointIcon"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTeleConsultation;
