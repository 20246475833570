// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import ErrorJson from "../AnimationJson/error-message.json";
import Modal from 'react-bootstrap/Modal';

const ErrorFull = (props) => {
    const { show, successMessage , animationtype } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Lottie animationData={ErrorJson} className='success-data-animation' />
                {successMessage && <p className='error-msg'>{successMessage}</p>}
            </Modal.Body>
        </Modal>
    )
}

export default ErrorFull;
