import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  appLeftBanner,
  appLoginRightBanner,
  appPhoneIcon,
  appSignInIcon,
} from "../images";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Toaster, toast } from "react-hot-toast";
import { DISPATCH_OTP_API , MATCH_OTP_API } from "../Constants";

const OtpForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [remaingtime, setRemaingTime] = useState(60);
  const [loadingData, setLoadingData] = useState(false);
  const [resenddisabled, setResendDisabled] = useState(false);
  // const [OTP, setOTP] = useState("");
  const [otpformvisible, setOtpFormVisible] = useState(false);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setError("");
  };

  useEffect(() => {
    let timer;
    if (remaingtime > 0 && !resenddisabled) {
      timer = setTimeout(() => {
        setRemaingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [remaingtime, resenddisabled]);

  const handleResend = () => {
    setRemaingTime(60);
    setResendDisabled(true);
  };

  const renderTime = (remainingTime) => {
    return (
      <p>
        Didn't receive the verification OTP {""}{" "}
        {remaingtime > 0 && <span>Request OTP {remainingTime} Sec</span>}
      </p>
    );
  };

  const handleGetOtpClick = async () => {
    if (!phoneNumber) {
      toast.error("Please enter your mobile number.");
      return;
    }

    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions and Privacy Policy.");
      return;
    }
    setLoadingData(true);
    try {
      const formData = new FormData();
      formData.append("mobile_no", phoneNumber);
      formData.append("device_id", "duw123344");

      const res = await fetch(DISPATCH_OTP_API,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await res.json();
      console.log("api data", data);

      if (data.status === true) {
        setOtp(data.otp_local);
        setIsOtpSent(true);
        toast.success("OTP Sent SuccessFull");
      } else {
        console.error("Failed TO get OTP");
        toast.error(data?.message);
      }
      setOtpFormVisible(true);
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleVerifyOtpClick = async () => {
    setLoadingData(true);
    if (!otp || otp.length < 6) {
      toast.error("Please Enter Valid OTP");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("mobile_no", phoneNumber);
      formData.append("device_id", "duw123344");
      formData.append("otp", otp);

      const otpres = await fetch(
        MATCH_OTP_API,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await otpres.json();

      if (data.access_token) {
        localStorage.setItem("ACCESS_TOKEN", data.access_token.trim());
        // console.log(data.access_token)
        navigate("/home");
        setIsOtpSent(true);
      }
    } catch (error) {
      toast.error("Error fetching OTP:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleEditClick = () => {
    setOtpFormVisible(false);
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    console.log(accessToken);
    if (accessToken) {
      navigate("/home");
    }
  }, []);

  return (
    <div className="home-review-banner">
      <div className="login-form-left-banner">
        <img src={appLoginRightBanner} alt="" />
      </div>
      <div className="Login-form">
        {otpformvisible && isOtpSent ? (
          <div className="otp-form-section">
            <div className="login-form-icon">
              <img src={appPhoneIcon} alt="" />
              <p>OTP VERIFICATION</p>
            </div>
            <div className="otep-detail-text">
              <p>
                Please enter verification code (OTP) sent <br /> to +91{" "}
                {phoneNumber} (<button onClick={handleEditClick}>Edit</button>)
              </p>
            </div>
            <div className="otp-section-boxs">
              <div className="Otp-box-from">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  className="otp-input-repo"
                />
              </div>
              <div className="resend-otp">
                <ResendOTP
                  className="get-otp-data"
                  renderTime={renderTime}
                  onResendClick={() => {
                    handleResend();
                    handleGetOtpClick();
                  }}
                  disabled={resenddisabled}
                />
              </div>
              <br />
              <button
                className="verify-otp-repo-btn"
                onClick={handleVerifyOtpClick}
                disabled={loadingData}
              >
                {loadingData ? "Please Wait...." : "VERIFY"}
              </button>
            </div>
          </div>
        ) : (
          <div className="login-form-section-repo">
            <div className="login-form-icon">
              <img src={appSignInIcon} alt="" />
              <p>SIGN IN</p>
            </div>
            <Form>
              <Form.Group
                controlId="validationCustom01"
                className="Login-form-repo"
              >
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Mobile Number"
                  defaultValue=""
                  value={phoneNumber}
                  maxLength={10}
                  onChange={handlePhoneNumberChange}
                  onKeyPress={handleKeyPress}
                />
              </Form.Group>
              <div className="check-box-repo">
                <Form.Check
                  aria-label="option 1"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <p>
                  I have read and agreed the{" "}
                  <NavLink>Terms & Conditions</NavLink> and{" "}
                  <NavLink>Privacy Policy</NavLink>
                </p>
              </div>
              {error && <p className="error-message">{error}</p>}
            </Form>
            <br />
            <button
              className="get-repo-btn"
              disabled={loadingData}
              onClick={handleGetOtpClick}
            >
              {loadingData ? "Please Wait..." : "Get OTP"}
            </button>
            <br />
          </div>
        )}
      </div>
      <div className="login-form-right-banner">
        <img src={appLeftBanner} alt="" />
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 2000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </div>
  );
};

export default OtpForm;
