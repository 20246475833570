import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";


const ViewCart = ({ total, link, productPrice, cartData, medicineCartData, lengthcount, prodlength }) => {

  useEffect(() => {
  }, [medicineCartData, cartData, productPrice]);


  return (
    <>
      <div className="go-to-cart-main-page-wrapper">
        <div className="cart-count-box-cover">
          {total !== "" && window.location.pathname === "/cart/diagnostictest" ? (
            <>
              <div className="cart-count-text">
                <p>
                  <span>{lengthcount + prodlength || 0}</span>item(s)
                </p>
              </div>
              <div className="go-cart-btn">
                <NavLink to={`${link}`}>
                  <button>Continue</button>
                </NavLink>
              </div>
            </>
          ) : (
            <>
              <div className="cart-count-text medicine-cart-repo-desc">
                {window.location.pathname === "/productdescription" ? (
                  <>
                    <p>
                      {productPrice && productPrice ? <>Total: <span className="prod-price-desc-item">₹{productPrice}</span></> : ''}
                    </p>
                    <p>
                      <span>{medicineCartData && medicineCartData?.length}</span>item(s)
                    </p>
                  </>
                ):(
                  <p className="item-cart-text-data">
                  <span>{medicineCartData && medicineCartData?.length}</span>item(s) Added To Your Cart
                  </p>
                )}
              </div>
              <div className="go-cart-btn">
                <NavLink to={`/${link}`}>
                  <button>View Cart</button>
                </NavLink>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};



export default ViewCart;
