import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowBackButton , fetchApi } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import GotoCart from "../Cart/GotoCart";
import { REQUESTOPTIONS , HEALTH_PACKAGE_API} from "../../Constants";
import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  appHomeCollectionIcon,
  appIncludeTestIcon,
  appPackageIcon,
} from "../../images";
import Modals from "../Modals";
import NoDataFoundComp from "../../components/NoDataFound";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";

const TestbyCategories = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [packageData, setPackageData] = useState([]);
  const [ispackageLoading, setPackageLoading] = useState(true);
  const [isErrorData, setErrorData] = useState([]);
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  
  
  useEffect(() => {
    fetchApi(HEALTH_PACKAGE_API, REQUESTOPTIONS, setPackageData, setPackageLoading, setErrorData);
  }, []);

  const handleCart = (id) => {
    const isItemInCart = CartData.some((item) => item.pack_id === id);

    if (!isItemInCart) {
      setCartData((prevData) => [
        ...prevData,
        ...packageData.data.filter((data) => data.pack_id === id),
      ]);
    } else {
      const updatedCart = CartData.filter((item) => item.pack_id !== id);
      setCartData(updatedCart);
    }
  };

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
    console.log(CartData)
  }, [CartData]);

  return (
    <>
      <div className="home-review-banner">
        <div className="lab-book-categories">
          <div className="app-header-wraper">
            <div className="header-navigation-icon">
              <NavLink onClick={ArrowBackButton}>
                <img src={ArrowBack} width="100%" alt="arrow-back" />
              </NavLink>
            </div>
            <div className="header-top-section">
              <div className="header-top-section-category-section">
                <h3>Select Test by Categories</h3>
                <Modals show={modalShow} onHide={() => setModalShow(false)} />
              </div>
              <div className="header-top-section-add-to-cart-section">
                <button className="">
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  <span className="Header-count-number">{CartData.length > 0 ? CartData.length : 0}</span>
                </button>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          </div>

          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input type="text" placeholder="Search" />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="health-packages-content-wrapper-box mb-5">
            <div className="container">
              {ispackageLoading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    {packageData.data.length > 0 ? (
                      packageData.data.map((data) => {
                        return (
                          <div className="health-pack-main-box">
                            <NavLink
                              to={`/package-description/${data.pack_id}`}
                            >
                              <div className="health-pack-image-box">
                                <img
                                  src={appPackageIcon}
                                  width="100%"
                                  alt="package"
                                />
                                <p>{data.package_name}</p>
                              </div>
                              <hr />
                              <div className="health-pack-price">
                                <h6>MB Price ₹ {data.pack_medi_price}</h6>
                                <p>₹ {data.pack_mrp}</p>
                                <span>{data.discount_percent} OFF</span>
                              </div>
                              <div className="health-pack-content-box">
                                <img
                                  src={appIncludeTestIcon}
                                  width="100%"
                                  alt="include-test"
                                />
                                <p>{data.parameter_count} Included Tests</p>
                              </div>
                              <div className="health-pack-content-box">
                                <img
                                  src={appHomeCollectionIcon}
                                  width="100%"
                                  alt="home"
                                />
                                <p className="sample-repo">
                                  Home Sample Collection
                                </p>
                              </div>
                            </NavLink>

                            {CartData.some(
                              (item) => item.pack_id === data.pack_id
                            ) ? (
                              <button
                                className="remove-cart-btn"
                                onClick={() => handleCart(data.pack_id)}
                              >
                                Remove 
                              </button>
                            ) : (
                              <div className="common-add-cart">
                                <button
                                  onClick={() => handleCart(data.pack_id)}
                                >
                                  <p>ADD TO CART</p>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <h6><NoDataFoundComp/></h6>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {CartData.length > 0 && <GotoCart link="diagnostictest"  CartData={CartData}/>}
      </div>
    </>
  );
};

export default TestbyCategories;
