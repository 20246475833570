import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { ArrowBackButton } from "../../utils/AllFunction";
import NoDataFoundComp from "../../components/NoDataFound";

import { UNIVERSAL_SEARCH_API } from "../../Constants";

import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  ArrowIconApp,
  SearchIconApp,
  NoDataFound,
} from "../../images";

const SearchTestMed = () => {
  const navigate = useNavigate();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [suggestionData, setSuggestionData] = useState([]);
  const [searchTestData, setSearchTestData] = useState([]);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };
  const searchrequesOption = { ...requestOptions };
  searchrequesOption.method = "POST";

  const handleNavigate = () => {
    navigate("/cart/diagnostictest");
  };

  useEffect(() => {
    const suggestion_Data = ["vit", "cov", "thy", "blo", "cul", "ant", "afb"];
    const randomIndex = Math.floor(Math.random() * suggestionData.length);
    const formData = new FormData();

    formData.append("universal_search", suggestion_Data[randomIndex]);

    searchrequesOption.body = formData;

    const suggestionApi = async () => {
      try {
        const reponse = await fetch(UNIVERSAL_SEARCH_API, searchrequesOption);

        const res = await reponse.json();
        if (res.status === 200) {
          setSuggestionData(res.testname);
        }
      } catch (error) {
        console.log(error);
      }
    };

    suggestionApi();
  }, []);

  const handleSearchInputChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const formData = new FormData();
    formData.append("universal_search", query);

    
    searchrequesOption.body = formData;

    try {
      if (query.length >= 3) {
        const reponse = await fetch(UNIVERSAL_SEARCH_API, searchrequesOption);
        const res = await reponse.json();
        if (res.status) {
          setSearchTestData(res.testname);
        } else {
        }
      }
    } catch (error) {
      console.error("Error fetching suggestions from new API:", error);
    }
  };

  function renderSearchResults(data) {
    return data.map((resultData) => (
      <NavLink to={`/test-description/${resultData.id}`} key={resultData.id}>
        <div className="search-suggest-item mb-3">
          <div className="test-name-box">
            <img src={SearchIconApp} alt="" />
            <div
              className="test-name"
              dangerouslySetInnerHTML={{
                __html: resultData?.name?.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          </div>
          <img src={ArrowIconApp} alt="" />
        </div>
      </NavLink>
    ));
  }

  return (
    <>
      <div className="home-review-banner">
      <div className="app-header-wraper">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-top-section d-flex justify-content-between">
            <div className="header-top-section-category-section">
              <h3>Search Lab Test</h3>
            </div>
            <div className="header-top-section-add-to-cart-section lab-cart-section">
              <button onClick={handleNavigate}>
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                <span className="Header-count-number">{CartData.length > 0 ? CartData.length : 0}</span>
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>
        <div className="app-searh-section">
          <div className="inputWithIcon">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              //   onFocus={handleSearchInputClick}
              onChange={handleSearchInputChange}
              //   onBlur={handleSearchInputBlur}
            />
            <button onClick="">
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="search-test-main-wrapper">
          <div className="suggestion-main-cover-wapper">
            <div className="suggestion-list-cover">
              <div className="search-result-box">
                {searchQuery.length > 0 ? (
                  searchTestData.length > 0 ? (
                    renderSearchResults(searchTestData)
                  ) : (
                    <NoDataFoundComp />
                  )
                ) : suggestionData.length > 0 ? (
                  renderSearchResults(suggestionData)
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchTestMed;
