import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../ContextApi";
import { useNavigate } from "react-router-dom";

const GotoCart = ({
  total,
  placeOrderlink,
  CartData,
  link,
  patientData,
  TestCartData,
  selectedTests,
  toast,
  mainCheckBoxArray,
  selectedTimeSlot,
  handlePlaceOrder
}) => {
  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);
  const navigate = useNavigate();
 

  const check_links = () => {
    // Filter out objects where packIds or testIds are undefined
    const filteredTests = selectedTests.filter(mytest => mytest.packIds && mytest.testIds);
  
    // Mapping through the filtered tests to remove undefined values from packIds and testIds
    const cleanedTests = filteredTests.map(mytest => ({
      packIds: mytest.packIds.filter(id => id !== undefined),
      testIds: mytest.testIds.filter(id => id !== undefined)
    }));
    console.log(cleanedTests[0]?.testIds.length)
    console.log(cleanedTests[0]?.packIds.length)

    if( cleanedTests[0]?.packIds?.length === 0 && cleanedTests[0]?.testIds?.length === 0){
      toast.error("Please select at least 1 test");
    }
    else if (
      cleanedTests[0]?.packIds?.length > 0 || cleanedTests[0]?.testIds?.length > 0 ||
      selectedTests.length === mainCheckBoxArray.length ||
      selectedTests.length >= mainCheckBoxArray.length
    ) {
      return ("/lab-addressdetails");
    }
    else if (selectedTests.length < mainCheckBoxArray.length) {
      toast.error("Please select at least 1 test");

    }
  };

  const handleContinueClick = () => {
    const link = check_links();
    navigate(link);
  };

  const isTimeSlotSelected = () => {
    if (selectedTimeSlot === null || selectedTimeSlot === "") {
      toast.error("Please Select Time Slot");
    }
  };

  return (
    <>
      <div className="go-to-cart-main-page-wrapper">
        <div className="cart-count-box-cover">
          {(total !== "" && window.location.pathname === "/cart/diagnostictest") ||
          TestCartData?.length > 0 ? (
            <>
              <div className="cart-count-text">
                <h5>
                  Total <span>₹ {total}</span>
                </h5>
                {TestCartData?.length > 0 ? (
                  <p>
                    <span>{TestCartData?.length || 0} </span> item(s)
                  </p>
                ) : (
                  <p>
                    <span>{CartData.length || 0}</span>item(s)
                  </p>
                )}
              </div>
              {TestCartData?.length > 0 ? (
                <div className="go-cart-btn">
                    <button onClick={handlePlaceOrder}>Pay Now</button>
                </div>
              ) : (
                <div className="go-cart-btn">
                  <NavLink to="/patients">
                    <button>Continue</button>
                  </NavLink>
                </div>
              )}
            </>
          ) : (
            <>
              {mainCheckBoxArray?.length > 0 ? (
                <>
                  <div className="cart-count-text">
                    <p>
                      <span>{mainCheckBoxArray?.length || 0}</span> Patient(s)
                      Selected
                    </p>
                  </div>
                  <div className="go-cart-btn">
                    <button
                      className="patient-btn"
                      onClick={handleContinueClick}
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="cart-count-text">
                    <p>
                      <span>{CartData?.length || 0}</span>item(s) Added To Your
                      Cart
                    </p>
                  </div>

                  {window.location.pathname === "/date-timeslot" ? (
                    selectedTimeSlot !== null ? (
                      <div className="go-cart-btn">
                        <NavLink to={placeOrderlink + '/diagnostictest'}>
                          <button>Continue</button>
                        </NavLink>
                      </div>
                    ) : (
                      <div className="go-cart-btn">
                        <button
                          className="patient-btn"
                          onClick={isTimeSlotSelected}
                        >
                          Continue
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="go-cart-btn">
                      <NavLink to={`/cart/${link}`}>
                        <button>Go To Cart</button>
                      </NavLink>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GotoCart;
