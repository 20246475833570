import { DentalIcon, LabTestIcon, MedicalloanIcon, MedicinesIcon, OPDIcon, RadiologyIcon, TeleconsultationIcon, appDignosticsupport } from "./images";

export const WellnessSixModules = [
    {
        "app_index_id": 1,
        "show_hide": 1,
        "name": "Diagnostic\nTest",
        "image": LabTestIcon
    },
    {
        "app_index_id": 2,
        "show_hide": 1,
        "name": "Tele\nconsultation",
        "image": TeleconsultationIcon
    },
    {
        "app_index_id": 3,
        "show_hide": 1,
        "name": "Buy\nMedicines",
        "image": MedicinesIcon
    },
    {
        "app_index_id": 4,
        "show_hide": 1,
        "name": "In-Person\nConsultation",
        "image": OPDIcon
    },
    {
        "app_index_id": 6,
        "show_hide": 1,
        "name": "Medical\nLoan",
        "image": MedicalloanIcon
    },
    {
        "app_index_id": 7,
        "show_hide": 1,
        "name": "Dental\nConsultation",
        "image":
            DentalIcon
    },
    {
        "app_index_id": 8,
        "show_hide": 1,
        "name": "Radiology\nScreening",
        "image": RadiologyIcon
    },
];

export const WellnessCorner = [
    {
        "app_index_id": 1,
        "show_hide": 1,
        "name": "Fitness",
        "image": "https://medibhai.com/assets/images/medibhai_images/fitness.svg"
    },
    {
        "app_index_id": 2,
        "show_hide": 1,
        "name": "Yoga",
        "image": "https://medibhai.com/assets/images/medibhai_images/yoga.svg"
    },
    {
        "app_index_id": 3,
        "show_hide": 1,
        "name": "Weight Loss",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/weight-loss.svg"
    },
    {
        "app_index_id": 4,
        "show_hide": 1,
        "name": "Nutrition",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/nutrition.svg"
    },
    {
        "app_index_id": 5,
        "show_hide": 1,
        "name": "Life Style",
        "image":
            "https://medibhai.com/assets/images/medibhai_images/Life_Style.svg"
    }
];
