import React, { useEffect } from "react";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import {
  ArrowBack,
  ArrowIconApp,
  DummyMedicine,
  SearchIconApp,
  appCartIcon,
  appHeartIcon,
  appMedicineDelivery,
  appMedicinePackingOrder,
  appPXImg,
  appPackingOrder,
  appUploadIcon,
  appYourOrder,
} from "../../images";
// import { ArrowBackButton } from "../../utils/AllFunction";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// import Modals from "../Modals";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../Animation/Loader";
import ViewCart from "./ViewCart";
import LocationSection from "../LocationSection/LocationSection";
import NoDataFoundComp from "../../components/NoDataFound";
import toast from "react-hot-toast";
import Mediloader from "../../Animation/Mediloader";
import { MEDICINE_CART_MANAGER_LIST, MEDICINE_UPDATE, SEARCH_MEDICINE_API } from "../../Constants";

const Medicine = (props) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [loadingData, setLoadingData] = useState({});
  const [searchInputClicked, setSearchInputClicked] = useState(false);
  const [searchdata, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [newApiSuggestions, setNewApiSuggestions] = useState([]);
  // const [showUploadPrescription, setShowUploadPrescription] = useState(true);
  const [showTrendingMedicines, setShowTrendingMedicines] = useState(true);
  const [showHowItWorks, setShowHowItWorks] = useState(true);
  const [medicineIndexData, setMedicineIndexData] = useState(null);
  const storedData = localStorage.getItem("medicineCartData");
  const initialData = storedData ? JSON.parse(storedData) : [];
  const [medicineCartData, setMedicineCartData] = useState(initialData);
  const [suggestionbar, setSuggestionBar] = useState(false);
  const [suggestionData, setSuggestionData] = useState(null);
  const [showdivsdata, setShowDivsData] = useState(false);
  const [showsuggestionResult, setShowSuggestionResult] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [responseOutStockData, setResponseOutStockData] = useState([]);
  const id = 0;

  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );
  let parsedmedicineData;

  const handleNavigation = () =>{
    props.token ?  window.history.go(-2) : window.history.back();
  }


  const navigate = useNavigate();
  // For SearchInput
  const handleSearchInputChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length >= 0) {
      try {
        const searchApi = await fetch(
          `https://api.1mg.com/webservices/search-lambda?name=${query}&city=${"mumbai"}`
        );
        if (!searchApi.ok) {
          console.error(
            "New API error:",
            searchApi.status,
            searchApi.statusText
          );
          return;
        }
        const newApiData = await searchApi.json();
        setNewApiSuggestions(newApiData.results);
        setShowSearchResults(false);
        // console.log("alterdata", newApiData?.results);
      } catch (error) {
        console.error("Error fetching suggestions from new API:", error);
      }
    }
  };

  // For InputSuggestions
  const handleSuggestionclick = (productName) => {
    const sanitizedProductName = productName.replace(/<\/?[^>]+(>|$)/g, "");
    setSearchQuery(sanitizedProductName);
    setShowSearchResults(true);
    // console.log(sanitizedProductName);

    const selectedProduct = newApiSuggestions.filter(
      (product) => product.name === sanitizedProductName
    );

    const updatedParsedMedicineData = {
      ...parsedmedicineData,
      text: sanitizedProductName,
      city: "mumbai",
    };

    // console.log("Updated parsedmedicineData:", updatedParsedMedicineData);
    // Call the API with updated data
    fetchMedicineData(updatedParsedMedicineData);
    const selectedProductName =
      selectedProduct.length > 0 ? selectedProduct[0].name : "";

    // console.log(`Suggestion click product name: ${selectedProductName}`);
  };

  const fetchMedicineData = async (updatedParsedMedicineData) => {
    setLoadingHomeData(true);
    const formdata = new FormData();
    formdata.append("medicine_name", updatedParsedMedicineData.text);
    formdata.append("city_name", updatedParsedMedicineData.city);

    try {
      const homeDataRes = await fetch(
        `${SEARCH_MEDICINE_API}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "POST",
          body: formdata,
        }
      );

      if (!homeDataRes.ok) {
        console.error("API error:", homeDataRes.status, homeDataRes.statusText);
        const errorData = await homeDataRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const medicineData = await homeDataRes.json();
      console.log("Fetched medicine data:", medicineData);
      setSuggestionData(Object.values(medicineData?.results));
      setShowSuggestionResult(true);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setLoadingHomeData(false);
    }
  };

  // For Search button
  const handleSearchButtonClick = () => {
    const trimmedQuery = searchQuery.trim();
    if (trimmedQuery.length >= 4) {
      const filteredData = newApiSuggestions.filter((item) =>
        item.name.toLowerCase().includes(trimmedQuery.toLowerCase())
      );
      // console.log(filteredData);
      setSearchData(filteredData);
      setNewApiSuggestions(filteredData);
      setShowSearchResults(true);

      // console.log(trimmedQuery);

      const updatedParsedMedicineData = {
        ...parsedmedicineData,
        text: trimmedQuery,
        city: "mumbai",
      };

      // Call the API with updated data
      fetchMedicineData(updatedParsedMedicineData);
      // console.log(updatedParsedMedicineData);
      setShowDivsData(true);
    } else {
      console.log("Search query must be at least four characters long.");
      setSearchData([]);
      setShowSearchResults(false);
    }
  };

  const suggestionDataResult = async () => {
    try {
      const searchApi = await fetch(
        `https://api.1mg.com/webservices/search-lambda?name=${"a"}&city=${"mumbai"}`
      );
      if (!searchApi.ok) {
        console.error("New API error:", searchApi.status, searchApi.statusText);
        return;
      }
      const newApiData = await searchApi.json();
      setSuggestionData(newApiData.results);
      setShowSearchResults(false);
      // console.log(newApiData?.results);
    } catch (error) {
      console.error("Error fetching suggestions from new API:", error);
    }
  };

  const handleInputFunction = () => {
    setShowDivsData(true);
    setSuggestionBar(true);
    suggestionDataResult();
  };

  // When Click on View All show search button
  const handleViewAllClick = () => {
    setShowDivsData(true);
    setSuggestionBar(true);
    setShowSearchResults(false);
    setShowSuggestionResult(false);
    medicineData();
  };
  const ArrowBackButton = () => {
    window.history.back();
    setShowDivsData(false);
    setSuggestionBar(false);
    setShowSuggestionResult(false);
    medicineData();
  };

  const SelectedMedicineDetails = (sku_id) => {
    if (!medicineIndexData && !suggestionData) {
      console.error(
        "Both medicineIndexData and suggestionData are null or undefined."
      );
      return;
    }

    const selectedMedicine = medicineIndexData
      ? medicineIndexData.find((medicine) => medicine.sku_id === sku_id)
      : null;

    const selectedSuggestionData = suggestionData
      ? suggestionData.find((medicine) => medicine.sku_id === sku_id)
      : null;

    if (selectedMedicine || selectedSuggestionData) {
      localStorage.setItem(
        "MedicineDetails",
        JSON.stringify(selectedMedicine || selectedSuggestionData)
      );
      navigate("/productdescription");
    } else {
      console.log(
        `Medicine with ID ${sku_id} not found in medicineIndexData or suggestionData.`
      );
    }
  };

  // call search_medicine api
  const medicineData = async () => {
    const storedIndexData = localStorage.getItem("medicineindexdata");
    if (storedIndexData) {
      parsedmedicineData = JSON.parse(storedIndexData);
    }
    const formdata = new FormData();
    formdata.append("medicine_name", parsedmedicineData?.text);
    formdata.append("city_name", parsedmedicineData?.city);
    try {
      const homeDataRes = await fetch(
        `${SEARCH_MEDICINE_API}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "POST",
          body: formdata,
        }
      );

      if (!homeDataRes.ok) {
        console.error("API error:", homeDataRes.status, homeDataRes.statusText);
        const errorData = await homeDataRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }
      const responseBody = await homeDataRes.text();
      if (!responseBody) {
        console.error("Empty response body");
        throw new Error("Empty response body");
      }

      const medicineData = JSON.parse(responseBody);
      setMedicineIndexData(Object.values(medicineData?.results));
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setLoadingHomeData(false);
    }
  };

  // TO search and show Another medicine APi
  useEffect(() => {
    if (newApiSuggestions) {
      const filterData = newApiSuggestions.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchData(filterData);
      // console.log("Search data:", filterData);
    }
  }, [searchQuery, newApiSuggestions]);

  // To Set Medicine Cart in Localstorage
  useEffect(() => {
    if (medicineCartData.length > 0) {
      localStorage.setItem(
        "medicineCartData",
        JSON.stringify(medicineCartData)
      );
    }
  }, [medicineCartData]);

  // To Add Data in manager api
  const AddProductData = async (medicineData) => {
    try {
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: medicineData?.sku_id,
            quantity: medicineData?.min_quantity,
            min_quantity: medicineData?.min_quantity,
            max_quantity: medicineData?.max_quantity,
          },
        ],
      };

      const updateData = await fetch(
        `${MEDICINE_UPDATE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${props.token ?? token}`,
          },
          body: JSON.stringify(requestBodyData),
        }
      );

      if (updateData.ok) {
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();
        setResponseData(resAgain?.skus_res);
        setResponseOutStockData(resAgain?.out_of_stock_res);
        const outOfStockRes = resAgain?.out_of_stock_res;
        if (outOfStockRes && Array.isArray(outOfStockRes)) {
          const ids = outOfStockRes.map((item) => item.id);
          if (ids.includes(medicineData.sku_id)) {
            toast.error("Medicine Out of Stock");
          } else {
            toast.success("Medicine Added");
          }
        }
      } else {
        console.error("Faild to fecth", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    medicineData();
    MangerListDataRepo();
  }, [pincodeData]);

  // TO Compare If data in manager_list_api
  const MangerListDataRepo = async () => {
    try {
      const ManagerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "GET",
        }
      );
      const ListDataRes = await ManagerListRes.json();

      setResponseData(ListDataRes?.skus_res);
      console.log(ListDataRes?.skus_res)

      setLoadingHomeData(false);
    } catch (error) {
      console.log(error);
      setLoadingHomeData(false);
    }
  };

  const handleCart = async (medicineData) => {
    try {
      setLoadingData((prevStates) => ({
        ...prevStates,
        [medicineData.sku_id]: true,
      }));

      // Fetch the latest quantity for the added product
      const managerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${props.token ?? token}`,
          },
          method: "GET",
        }
      );
      const listDataRes = await managerListRes.json();
      if (listDataRes?.skus_res && listDataRes?.skus_res.qty) {
        medicineData.qty = listDataRes?.skus_res.qty;
      }

      await AddProductData(medicineData);

      MangerListDataRepo();

      setResponseData((prevData) => [...prevData, medicineData]);

      setLoadingData((prevStates) => ({
        ...prevStates,
        [medicineData.sku_id]: false,
      }));
    } catch (error) {
      console.log(error);
      setLoadingData((prevStates) => ({
        ...prevStates,
        [medicineData.sku_id]: false,
      }));
    }
  };

  // To Increment
  const handleIncrement = async (filteredItem) => {
    try {
      setLoadingData((prevStates) => ({
        ...prevStates,
        [filteredItem.id]: true,
      }));
      const updatedQuantity = Math.max(filteredItem.qty + 1);

      // Update the cart locally
      const updatedCart = responseData.map((item) => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity,
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity,
          },
        ],
      };

      // Call cart_manager_update API to update the cart
      const updateData = await fetch(
        `${MEDICINE_UPDATE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${props.token ?? token}`,
          },
          body: JSON.stringify(requestBodyData),
        }
      );

      if (updateData.ok) {
        // If the update is successful, fetch the updated cart data
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${props.token ?? token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();
        console.log(resAgain?.skus_res);

        // Set loading to false after receiving response
        setLoadingData((prevStates) => ({
          ...prevStates,
          [filteredItem.id]: false,
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // To Decrement
  const handleDecrement = async (filteredItem) => {
    try {
      setLoadingData((prevStates) => ({
        ...prevStates,
        [filteredItem.id]: true,
      }));
      // Calculate updated quantity ensuring it respects minimum quantity
      let updatedQuantity = Math.max(filteredItem.qty - 1, 0);

      // If the updated quantity is less than the minimum quantity, set it to 0
      if (updatedQuantity < filteredItem.min_quantity) {
        updatedQuantity = 0;
      }

      // Update the cart locally
      const updatedCart = responseData.map((item) => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity,
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity,
          },
        ],
      };
      // Call cart_manager_update API to update the cart
      const updateData = await fetch(
        `${MEDICINE_UPDATE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${props.token ?? token}`,
          },
          body: JSON.stringify(requestBodyData),
        }
      );

      if (updateData.ok) {
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();

        // Update UI after successful API response
        setResponseData(resAgain?.skus_res);
        console.log(resAgain?.skus_res);

        // Set loading to false after receiving response
        setLoadingData((prevStates) => ({
          ...prevStates,
          [filteredItem.id]: false,
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">
          <div className="header-navigation-icon">
            <NavLink onClick={handleNavigation}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Medicines</h3>
              <LocationSection />
            </div>
            <div className="header-top-section-add-to-cart-section">
              <NavLink to="/cart">
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                <span className="Header-count-number">
                  {responseData ? responseData.length : 0}
                </span>
              </NavLink>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>
        <div
          className={`app-searh-section ${searchInputClicked ? "hidden" : ""}`}
        >
          <div className="inputWithIcon">
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearchInputChange}
              onClick={handleInputFunction}
            />
            <button onClick={handleSearchButtonClick}>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <>
          {!showSearchResults && suggestionbar && (
            <>
              {searchQuery.length > 0 && newApiSuggestions?.length > 0
                ? newApiSuggestions.map((suggestion) => (
                  <div
                    className="suggest-item"
                    onClick={() => handleSuggestionclick(suggestion.name)}
                  >
                    <p>
                      <img src={SearchIconApp} alt="" />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: suggestion.name?.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></div>
                      <img src={ArrowIconApp} alt="" />
                    </p>
                  </div>
                ))
                : suggestionData &&
                suggestionData.map((data) => (
                  <div
                    className="suggest-item"
                    onClick={() => handleSuggestionclick(data?.name)}
                  >
                    <p>
                      <img src={SearchIconApp} alt="" />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.name?.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></div>
                      <img src={ArrowIconApp} alt="" />
                    </p>
                  </div>
                ))}
            </>
          )}
        </>
        {loadingHomeData && loadingHomeData ? (
          <p>
            <Loader />
          </p>
        ) : suggestionData && suggestionData.length > 0 ? (
          <>
            {showsuggestionResult && (
              <div className="tranding-medicine-container">
                <div className="row">
                  {Array.isArray(suggestionData) &&
                    suggestionData.length > 0 &&
                    suggestionData.map((medicineData) => {
                      return (
                        <div
                          className="col-lg-6 col-6 mb-4"
                          key={medicineData.id}
                        >
                          <div className="tranding-medicine-box">
                            <div
                              className="select-product-data"
                              onClick={() =>
                                SelectedMedicineDetails(medicineData.sku_id)
                              }
                            >
                              <div className="tranding-medicine-image">
                                <div className="row">
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <div className="medicine-rx-image">
                                      {medicineData.rx_required === false ? (
                                        <></>
                                      ) : (
                                        <img
                                          src={appPXImg}
                                          alt={medicineData.name}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <div className="medicine-tranding-main-image">
                                      <img
                                        src={medicineData.image}
                                        width="100%"
                                        alt={
                                          medicineData.name.length > 8
                                            ? `${medicineData.name.slice(
                                              0,
                                              8
                                            )}...`
                                            : medicineData.name
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    {medicineData.discount_percent ? (
                                      <div className="medicine-discount">
                                        <p>{medicineData.discount_percent}</p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="tranding-medicine-detail">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: medicineData.name?.replace(
                                      /(<? *script)/gi,
                                      "illegalscript"
                                    ),
                                  }}
                                ></p>
                              </div>
                              <div className="tranding-medicine-price">
                                <p>Min Qty:{medicineData.min_quantity}</p>₹{" "}
                                {medicineData.discounted_price}
                                <span>₹ {medicineData.price}</span>
                              </div>
                            </div>
                            <div className="tranding-medicine-container">
                              {responseData.length === 0 ||
                                !responseData.some(
                                  (item) => item.id === medicineData.sku_id
                                ) ? (
                                <button
                                  className="tranding-medicine-add-cart"
                                  onClick={() => handleCart(medicineData)}
                                  disabled={loadingData[medicineData.sku_id]}
                                >
                                  {loadingData[medicineData.sku_id]
                                    ? "Adding..."
                                    : "ADD"}
                                </button>
                              ) : (
                                responseData
                                  .filter(
                                    (item) => item.id === medicineData.sku_id
                                  )
                                  .map((filteredItem) => (
                                    <div
                                      className={`tranding-medicine-add-cart-add ${loadingData[filteredItem.id]
                                        ? "loading"
                                        : ""
                                        }`}
                                      key={filteredItem.id}
                                    >
                                      {loadingData[filteredItem.id] && (
                                        <p><Mediloader /></p>
                                      )}
                                      {!loadingData[filteredItem.id] &&
                                        filteredItem.qty > 0 && (
                                          <>
                                            <div
                                              className="image-add-icon"
                                              onClick={() =>
                                                handleDecrement(filteredItem)
                                              }
                                            >
                                              <p>-</p>
                                            </div>
                                            <span>{filteredItem.qty}</span>
                                            <div
                                              className="image-add-icon"
                                              onClick={() =>
                                                handleIncrement(filteredItem)
                                              }
                                            >
                                              <p>+</p>
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {!showdivsdata && (
          <>
            <div className="upload-prescription-wraper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-10">
                    <div className="upload-prescription-right-side">
                      <h3>Upload Prescription to place Order</h3>
                      <p className="mobile-text-pres">
                        What is valid Prescription?
                      </p>
                      <div className="upload-prescription-right-side-icon-wraper">
                        <NavLink to={`/UploadPrescription/${id}`}>
                          <div className="upload-prescription-right-side-icon">
                            <img
                              src={appUploadIcon}
                              width="100%"
                              alt="upload-icon"
                            />
                          </div>
                          <p>Upload Prescription</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showTrendingMedicines && (
              <div className="tranding-medicine-wraper">
                <div className="container">
                  <div className="tranding-medicine-heading">
                    <h3>Trending Medicines</h3>
                    <div onClick={handleViewAllClick}>
                      <p>View All</p>
                    </div>
                  </div>
                  {loadingHomeData && loadingHomeData ? (
                    <p>
                      <Loader />
                    </p>
                  ) : medicineIndexData && medicineIndexData?.length > 0 ? (
                    <div className="tranding-medicine-container">
                      <div className="row">
                        {Array.isArray(medicineIndexData) &&
                          medicineIndexData.length > 0 &&
                          medicineIndexData.slice(0, 6).map((medicineData) => {
                            return (
                              <div
                                className="col-lg-6 col-6 mb-lg-0 mb-md-0 mb-4"
                                key={medicineData.id}
                              >
                                <div className="tranding-medicine-box">
                                  <div
                                    className="select-product-data"
                                    onClick={() =>
                                      SelectedMedicineDetails(
                                        medicineData.sku_id
                                      )
                                    }
                                  >
                                    <div className="tranding-medicine-image">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-4 col-4">
                                          <div className="medicine-rx-image">
                                            {medicineData.rx_required === false ? (
                                              <></>
                                            ) : (
                                              <img
                                                src={appPXImg}
                                                alt={medicineData.name}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                          <div className="medicine-tranding-main-image">
                                            <img
                                              src={medicineData.image}
                                              width="100%"
                                              alt={
                                                medicineData.name.length > 8
                                                  ? `${medicineData.name.slice(
                                                    0,
                                                    8
                                                  )}...`
                                                  : medicineData.name
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                          {medicineData.discount_percent ? (
                                            <div className="medicine-discount">
                                              <p>
                                                {medicineData.discount_percent}
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tranding-medicine-detail">
                                      <p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: medicineData.name?.replace(
                                              /(<? *script)/gi,
                                              "illegalscript"
                                            ),
                                          }}
                                        ></div>
                                      </p>
                                    </div>
                                    <div className="tranding-medicine-price">
                                      <p>Min Qty:{medicineData.min_quantity}</p>
                                      ₹ {medicineData.discounted_price}
                                      <span>₹ {medicineData.price}</span>
                                    </div>
                                  </div>

                                  <div className="tranding-medicine-container">
                                    {responseOutStockData.some(
                                      (item) => item.id === medicineData.sku_id
                                    ) ||
                                      responseData.length === 0 ||
                                      !responseData.some(
                                        (item) => item.id === medicineData.sku_id
                                      ) ? (
                                      <button
                                        className="tranding-medicine-add-cart"
                                        onClick={() => handleCart(medicineData)}
                                        disabled={
                                          loadingData[medicineData.sku_id]
                                        }
                                      >
                                        {loadingData[medicineData.sku_id]
                                          ? "Adding..."
                                          : "ADD"}
                                      </button>
                                    ) : (
                                      responseData
                                        .filter(
                                          (item) =>
                                            item.id === medicineData.sku_id
                                        )
                                        .map((filteredItem) => (
                                          <div
                                            className={`tranding-medicine-add-cart-add ${loadingData[filteredItem.id]
                                              ? "loading"
                                              : ""
                                              }`}
                                            key={filteredItem.id}
                                          >
                                            {loadingData[filteredItem.id] && (
                                              <p><Mediloader /></p>
                                            )}
                                            {!loadingData[filteredItem.id] &&
                                              filteredItem.qty > 0 && (
                                                <>
                                                  <div
                                                    className="image-add-icon"
                                                    onClick={() =>
                                                      handleDecrement(
                                                        filteredItem
                                                      )
                                                    }
                                                  >
                                                    <p>-</p>
                                                  </div>
                                                  <span>
                                                    {filteredItem.qty}
                                                  </span>
                                                  <div
                                                    className="image-add-icon"
                                                    onClick={() =>
                                                      handleIncrement(
                                                        filteredItem
                                                      )
                                                    }
                                                  >
                                                    <p>+</p>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        ))
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </div>
            )}

            {showHowItWorks && (
              <>
                <div className="how-its-work-section">
                  <h3>How it works?</h3>
                </div>

                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                  className="swiper-cover-repo mySwiper how-its-work-main-section"
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide className="your-order-section swiper-wrap-repo">
                      <div className="your-order-section-container">
                        <h3>Your Order</h3>
                        <img src={appYourOrder} width="100%" alt="Order" />
                        <p>
                          Add products to your cart, enter your details and
                          confirm.
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="your-order-section swiper-wrap-repo">
                      <div className="your-order-section-container">
                        <h3>Picking Your Order</h3>
                        <img
                          src={appMedicinePackingOrder}
                          width="100%"
                          alt="Order"
                        />
                        <p>
                          Your order is being pixked and now will be forward for
                          packaging
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="your-order-section swiper-slide swiper-wrap-repo">
                      <div className="your-order-section-container">
                        <h3>Packing Your Order</h3>
                        <img
                          className="packing-order"
                          src={appPackingOrder}
                          width="100%"
                          alt="Order"
                        />
                        <p>
                          We are packing your order and will be out for delivery
                          soon.
                        </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="your-order-section swiper-slide swiper-wrap-repo">
                      <div className="your-order-section-container">
                        <h3>Deliver</h3>
                        <img
                          className="packing-order"
                          src={appMedicineDelivery}
                          width="100%"
                          alt="Order"
                        />
                        <p>
                          Your order has been prepared and out for delivery.it
                          will be deliverd soon.
                        </p>
                      </div>
                    </SwiperSlide>
                  </div>
                  {/* <div className="swiper-pagination" /> */}
                </Swiper>
              </>
            )}
          </>
        )}
        {/* {!searchInputClicked &&
          responseOutStockData.length === 0 &&
          responseData &&
          responseData.length > 0 && ( */}
            {responseData.length !== 0 && (
              <ViewCart medicineCartData={responseData} link="cart" />
            )}
          {/* )} */}
      </div>
    </>
  );
};

export default Medicine;
