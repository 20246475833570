import React, { useEffect  , useState} from 'react';
import { useNavigate } from 'react-router';
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS , HOME_INDEX_API } from "../../Constants";
import useQuery from "../../CustomHooks/useQuery"


const LoadPackage = (props) => {
    const navigate = useNavigate();

    const [packagesData , setPackagesData] = useState([]);
    const token = localStorage.getItem("ACCESS_TOKEN");
    const user_package_id = useQuery('user_package_id') || 46427 ;
    const package_id = useQuery('package_id') || 56 ;

      const getPackageDetails = async () => {
        try {
          const packageDataRes = await fetch(
            `${process.env.REACT_APP_API_URL}/api/home_index/getPackageDetails?package_id=${package_id}&user_package_id=${user_package_id}`,
            {
              headers: {
                Authorization: `${props.token ?? token}`,
              },
            }
          );
  
          if (!packageDataRes.ok) {
            const errorData = await packageDataRes.json().catch(() => null);
            console.error("Error details:", errorData);
            throw new Error("Network response was not ok");
          }
  
          const res = await packageDataRes.json();
          setPackagesData(res?.data?.package_data)
          
        } catch (error) {
          console.error("Error fetching home data:", error);
        } 
      };
    
    useEffect(() =>{

        getPackageDetails();
    },[])

    useEffect(() =>{
        navigate(`/packagedetailbox/${package_id}/${user_package_id}/${packagesData.title}/${packagesData.valid_from}/${packagesData.valid_to}/${packagesData.desciption}`)
    },[packagesData])

  return (
    <>
    </>
  )
}

export default LoadPackage