import React from 'react'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, headerLogo , Nutition_banner} from '../../images'
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const NutritionConsultation = () => {
    return (
        <>
            <div className="home-review-banner">
                <div className="home-app-header nutrition-repo-box">
                    <div className="header-navigation-icon">
                        <NavLink onClick={ArrowBackButton}>
                            <img src={ArrowBack} width="100%" alt="arrow-back" />
                        </NavLink>
                    </div>
                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p>Nutrition Consultation</p>
                        </div>
                    </div>
                </div>

                <div className="ambulance-cover-section">
                    <div className="super-watch-header-image">
                        <img src={Nutition_banner} width="100%" alt="Nutrition_banner" />
                        <p>Enter Patient Details</p>
                    </div>
                    <div className="super-top-heading">
                        <p>Teleconsultation Will be Conduct for this person</p>
                    </div>
                    <div className="ambulance-form-box">
                        <label>Enter Name</label>
                        <input type="text" placeholder="Enter Name" />
                        <label>Select Date</label>
                        <input type="date" placeholder="Enter Date" />
                        <label>Enter Age</label>
                        <input type="text" placeholder="Enter Age" />
                        <label>Select Gender</label>
                        <div className="gender-select-box">
                            <Form.Check
                                type="radio"
                                label="Male"
                                name="gender" // Add a common name for all radio buttons in the group
                                id="male-radio"
                            />
                            <Form.Check
                                type="radio"
                                label="Female"
                                name="gender"
                                id="female-radio"
                            />
                            <Form.Check
                                type="radio"
                                label="Other"
                                name="gender"
                                id="other-radio"
                            />
                        </div>
                        <input type="text" placeholder="Enter Mobile Number" />
                        <input type="text" placeholder="Enter Email Address" />
                        <button id="submitButton">Submit</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default NutritionConsultation