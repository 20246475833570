import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import GotoCart from "./GotoCart";
import { DENTAL_TIME_SLOT_API } from "../../Constants";

import {
  ArrowBack,
  appCartIcon,
  timeHome,
  appHeartIcon,
} from "../../images";
import Loader from "../../Animation/Loader";
import { AppContext } from "../../ContextApi";

const DateSlotTime = () => {
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [timeSlotData, setTimeSlotData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [timeSlotOption, setTimeSlotOptions] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const { requestOptions } = useContext(AppContext);

  const [currentMonth, setCurrentMonth] = useState('');

  useEffect(() => {
    const today = new Date();
    const month = today.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    setCurrentMonth(month);
  }, []);

  useEffect(() => {
    const TimeSlotDetails = async () => {
      setDataLoading(true);
      try {
        const response = await fetch(DENTAL_TIME_SLOT_API, requestOptions);

        const res = await response.json();
        if (res.status) {
          setTimeSlotData(res.data);
          setDataLoading(false);
          setTimeSlotOptions(
            res?.data?.tomorrow_time_slot?.length > 0
              ? res?.data?.tomorrow_time_slot
              : []
          );
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    TimeSlotDetails();
  }, []);

  const handleTimeSlot = (currentDate, index) => {
    const today = new Date(); // Get today's date
    if (
      today.getDate() === currentDate.getDate() &&
      today.getMonth() === currentDate.getMonth() &&
      today.getFullYear() === currentDate.getFullYear()
    ) {
      // If current date is today
      setTimeSlotOptions(
        timeSlotData?.today_time_slot?.length > 0
          ? timeSlotData.today_time_slot
          : timeSlotData?.tomorrow_time_slot?.length > 0
          ? timeSlotData.tomorrow_time_slot
          : []
      );
    } else {
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      if (
        tomorrow.getDate() === currentDate.getDate() &&
        tomorrow.getMonth() === currentDate.getMonth() &&
        tomorrow.getFullYear() === currentDate.getFullYear()
      ) {
        setTimeSlotOptions(
          timeSlotData?.tomorrow_time_slot?.length > 0
            ? timeSlotData.tomorrow_time_slot
            : []
        );
      } else {
        setTimeSlotOptions(
          timeSlotData?.time_slot?.length > 0 ? timeSlotData.time_slot : []
        );
      }
    }
    setActiveIndex(index);
    // Set the formatted date in localStorage
    const formattedDate = currentDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    localStorage.setItem("SELECTED_TEST_DATE", JSON.stringify(formattedDate));
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
    localStorage.setItem(
      "SELECTED_TIMESLOT",
      JSON.stringify(event.target.value)
    );
  };

  useEffect(() => {
    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [CartData]);

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-top-section">
            <div className="header-top-section-category-section">
              <h3>Select Date and Time</h3>
            </div>
            <div className="header-top-section-add-to-cart-section">
              <button className="">
                <img src={appCartIcon} width="100%" alt="Cart-icon" />
                <span className="Header-count-number">{CartData.length > 0 ? CartData.length : 0}</span>
              </button>
            </div>
            <div className="header-top-section-add-to-cart-section-hert">
              <img src={appHeartIcon} width="100%" alt="heart-icon" />
            </div>
          </div>
        </div>
        <>
          <div className="patient-main-box">
            <img src={timeHome} width="100%" alt="review" />
            <p>Select date for Home sample collection</p>
          </div>
          <div className="para-content-p">
            <p>Some test/packages may require fasting*</p>
          </div>
          <div className="date-calender-box">
            <div className="date-heading-box">
              <p>Select date : {currentMonth}</p>
            </div>
            {isDataLoading ? (
              <Loader />
            ) : (
              <div className="date-slot-main-wrapper">
                {timeSlotData?.date_range?.length > 0 &&
                  timeSlotData.date_range.map((time_data, index) => {
                    const currentDate = new Date(time_data);
                    const day = currentDate.toLocaleDateString("en-US", {
                      weekday: "short",
                    });
                    const date = currentDate.getDate();
                    const month = currentDate.toLocaleDateString("en-US", {
                      month: "short",
                    });

                    return (
                      <div className="date-box" key={time_data}>
                        <div className="per-date-box">
                          <button
                            id="date"
                            className={`element ${
                              index === activeIndex ? "activeDatebox" : ""
                            }`}
                            onClick={() => handleTimeSlot(currentDate, index)}
                          >
                            <p>{day}</p>
                            <hr className="element1" />
                            <span>{date}</span>
                            <br />
                            <span>{month}</span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="time-section">
            <div className="time-heading">
              <p>Select Time</p>

              <div className="patient-input-form">
                <select
                  name="time"
                  id="time"
                  className="relation"
                  onChange={handleTimeSlotChange}
                >
                  <option value="">Select Time</option>
                  {timeSlotOption.length > 0 &&
                    timeSlotOption.map((timeSlot, index) => (
                      <option key={index} value={timeSlot}>
                        {timeSlot}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </>
        <GotoCart
          placeOrderlink="/placeorder"
          toast={toast}
          selectedTimeSlot={selectedTimeSlot}
          CartData={CartData}
      />
      </div>
      
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </>
  );
};

export default DateSlotTime;
