import { React, useContext, useEffect, useState } from "react";
import ProfileHeader from "../Components/Header";
import Loader from "../../../Animation/Loader";
import { REQUESTOPTIONS, NO_PACKAGE_GIF , MY_ACCOUNT_API } from "../../../Constants";
import { fetchApi } from "../../../utils/AllFunction";

const MyPackageDetails = () => {
  const [isPackageDataloading, setPackageDataLoading] = useState(true);
  const [packageDetailsData, setPackageDetailsData] = useState([]);
  const [isErrorData, setErrorData] = useState([]);

  useEffect(() => {
    fetchApi(
      MY_ACCOUNT_API,
      REQUESTOPTIONS,
      setPackageDetailsData,
      setPackageDataLoading,
      setErrorData
    );
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <ProfileHeader Heading="My Package Details" />
        {isPackageDataloading ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover">
            {packageDetailsData.data?.my_package_data?.length > 0 ? (
              <>
                <div className="package-details-main-cover-wrapper">
                  <div className="wallet-points-cover-wrapper">
                    <h5>
                      Your Pending wallet points is :{" "}
                      <span className="wallet-point">
                        {packageDetailsData?.data?.wallet_data?.module_amount}
                      </span>{" "}
                      points
                    </h5>
                  </div>
                </div>
                <div className="package-card-wrapper mt-3">
                  <div className="package-card">
                    <h6>
                      Package :{" "}
                      <span className="package-type">
                        {
                          packageDetailsData.data?.my_package_data[0]
                            ?.package_name
                        }
                      </span>
                    </h6>
                    <ul>
                      <li>
                        Package partner :{" "}
                        <span className="package-partner">
                          {
                            packageDetailsData.data?.my_package_data[0]
                              .corporate_name
                          }
                        </span>
                      </li>
                      <li>
                        Order Date :{" "}
                        <span className="package-order-date">
                          {
                            packageDetailsData.data?.my_package_data[0]
                              .created_at
                          }
                        </span>
                      </li>
                      <li>
                        Expiry Date :{" "}
                        <span className="package-expiry-date">
                          {
                            packageDetailsData.data?.my_package_data[0]
                              .expiring_on
                          }
                        </span>
                      </li>
                      <li>
                        Wallet Amount :{" "}
                        <span className="package-expiry-date">
                          {
                            packageDetailsData.data?.my_package_data[0]
                              .package_wallet_amount
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-package-data-found">
                <img src={NO_PACKAGE_GIF} alt="no-package-gif"></img>
                <p className="text-center">No Packages Found</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MyPackageDetails;
