import { React, useState, useEffect, useContext } from "react";
import { ArrowBack, headerLogo, dummyDoctorIcon } from "../../images";
import { ArrowBackButton } from "../../utils/AllFunction";
import { NavLink } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import { REQUESTOPTIONS, IMG_PATH, HOME_INDEX_API } from "../../Constants";

const OpdHome = () => {
  // const [dentalConsultationData, setDentalConsultationData] = useState([]);
  const [specialistData, setSpecialistData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [isErrorData, setisErrorData] = useState(false);
  const [optionsWithAttribute, setOptionsWithAttribute] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hospitalname, setHospitalname] = useState("");
  const [doctorName, setDoctorName] = useState("");

  useEffect(() => {
    setDataLoading(true);
    const OpdspecialList = async () => {
      try {
        const response = await fetch(`${HOME_INDEX_API}`, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          setDataLoading(false);
          const mappedOptions =
            res.department.departments?.map((state) => ({
              value: state.name,
              label: state.name,
              id: state.id,
            })) || [];
          res.department.departments?.map((state) => {
            console.log(state.id);
          });
          setOptionsWithAttribute(mappedOptions);
          setSpecialistData(res?.department?.departments);
        } else {
          setDataLoading(false);
        }
      } catch (error) {
        console.log(error);
        setisErrorData(true);
      }
    };

    OpdspecialList();
  }, []);

  useEffect(() => {
    console.log(selectedOption?.id);
  }, [selectedOption]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "8px",
      border: "0.5px solid #70d3ff",
      padding: "0px 3px",
      fontSize: "13px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#36a2eb" : "white",
      color: state.isSelected ? "white" : "black",
      borderRadius: "5px",
      zIndex: 999,
      fontSize: "13px",
    }),
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
        </div>
        <div className="dental-consultation-main-wrapper-cover">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="opd-main-wrapper-cover">
                <div className="opd-home-banner-cover">
                  <h6 className="">
                    Doctor <span className="opd-title">Appointment</span>
                  </h6>
                </div>
                <div className="opd-booking-form-container mt-3">
                  <Form.Group
                    className="mb-3 book-now-input-box"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Doctor Name"
                      onChange={(e) => setDoctorName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 book-now-input-box"
                    controlId="exampleForm.ControlInput1"
                    onChange={(e) => setHospitalname(e.target.value)}
                  >
                    <Form.Control type="text" placeholder="Hospital" />
                  </Form.Group>
                  <Select
                    className="mb-3"
                    options={optionsWithAttribute}
                    isSearchable={true}
                    placeholder="Select specialist"
                    styles={customStyles}
                    value={selectedOption}
                    onChange={(selectedOption) =>
                      setSelectedOption(selectedOption)
                    }
                  />
                  {/* {console.log(selectedOption)} */}
                  <div className="search-doctor-btn-box d-flex justify-content-center align-items-center">
                    <button>
                      {console.log(hospitalname)}
                      <NavLink
                        to={`/doctor-opd?${
                          selectedOption?.id === undefined || selectedOption?.id === ""
                            ? ""
                            : `doc_id=${selectedOption.id}&`
                        }${
                          doctorName === undefined || doctorName === ""
                            ? ""
                            : `doctor_name=${doctorName.trim()}&`
                        }${
                          hospitalname === undefined || hospitalname === ""
                            ? ""
                            : `hospital_name=${hospitalname.trim()}&`
                        }`}
                      >
                        Search
                      </NavLink>
                    </button>
                  </div>
                </div>

                <div className="opd-specialist-main-cover mb-5">
                  <div className="row mb-3">
                    {specialistData.length > 0 ? (
                      specialistData.map((listData) => {
                        return (
                          <div className="col-6 p-2">
                            <div className="specialist-type-product-box">
                              <NavLink
                                to={`/doctor-opd?${
                                  listData?.id === undefined || listData?.id === ""
                                    ? ""
                                    : `doc_id=${listData.id}&`
                                }${
                                  doctorName === undefined || doctorName === ""
                                    ? ""
                                    : `doctor_name=${doctorName.trim()}&`
                                }${
                                  hospitalname === undefined || hospitalname === ""
                                    ? ""
                                    : `hospital_name=${hospitalname.trim()}&`
                                }`}
                              >
                                <div className="specialist-img-box">
                                  {listData?.dept_logo ? (
                                    <img
                                      src={`${IMG_PATH}/${listData.dept_logo}`}
                                      alt="specialist-img"
                                    />
                                  ) : (
                                    <img
                                      src={dummyDoctorIcon}
                                      className="dummy-icon"
                                      alt="default-specialist-img"
                                    />
                                  )}
                                </div>
                              </NavLink>

                              <h6 class="text-center">{listData?.name}</h6>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OpdHome;
