import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  appAssistance,
  appConductTest,
  appCovidSupport,
  appDignosticsupport,
  appHome,
  appInsuranceClaimSupport,
  appKnowMore,
  appOtherSupport,
  appPathlogy,
  appProfile,
  appRadiologysupport,
  appSupport,
  appTalkDoctor,
  appWallet,
  appWhatsapp,
  hospitalsupport,
  teleconsultationsupport,
} from "../images";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SuccessFull from "../Animation/SuccessFull";
import { fetchApi } from "../utils/AllFunction";
import { WHATSAPP_NUMBER } from "../Constants";

const Footer = () => {
  const [isActive, setIsActive] = useState(false);
  // const [userinfodata, setUserInfoData] = useState(JSON.parse(localStorage.getItem('userInfo')) || {});
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [selectdata, setSelectData] = useState(null);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [profileData, setProfileData] = useState([]);

  // console.log(userinfodata)
  const handleButtonClick = (buttonIndex, buttonData) => {
    setActiveButton(activeButton === buttonIndex ? null : buttonIndex);
    setSelectData(activeButton === buttonData ? null : buttonData);
    console.log(buttonData, buttonIndex);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append(
      "support_request_no",
      profileData.data?.profile_details?.mobile
    );
    formData.append("support_request_type", selectdata);

    console.log("Form Data:", formData);

    try {
      const wellnessData = await fetch(
        `${process.env.REACT_APP_API_URL}/general/request_support`,
        {
          method: "POST",
          headers: {
            Authorization: `${token}`,
          },
          body: formData,
        }
      );

      const responseData = await wellnessData.json();
      console.log("Response Data:", responseData);

      if (!wellnessData.ok) {
        console.error(
          "API error:",
          wellnessData.status,
          wellnessData.statusText
        );
        const errorData = await wellnessData.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      if (responseData.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(responseData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          setShowModal(false);
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleWhatsApplink = () => {
    const whatsappLink = `https://wa.me/${WHATSAPP_NUMBER}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <div class="footer-wrapper">
        <div class="footer-cover">
          <div class="home">
            <NavLink to="/home">
              <button>
                <div class="footer-image-box">
                  <img src={appHome} alt="Home" />
                </div>
                <p>Home</p>
              </button>
            </NavLink>
          </div>
          <div className="support">
            <button onClick={handleShow}>
              <div className="footer-image-box">
                <img src={appSupport} alt="support" />
              </div>
              <p>Help</p>
            </button>
          </div>
          <div class="whatsapp">
            <button onClick={handleWhatsApplink}>
              <div class="footer-image-box">
                <img src={appWhatsapp} alt="Whatsapp" />
              </div>
              <p>Whatsapp</p>
            </button>
          </div>
          <div class="profile">
            <NavLink to="/profile">
              <button>
                <div class="footer-image-box">
                  <img src={appProfile} alt="Profile" />
                </div>
                <p>Profile</p>
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="content-support-repo"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="Reach-repo">Reach Us</h5>
            <p className="option-repo">
              Please select you options and click on submit
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body classname="modal-covid-repo">
          <div className="Reach-us-cover-section">
            <div className="reach-us-image-box">
              <img src={appCovidSupport} alt="covid-test" />
              <p>Covid 19 Support</p>
            </div>
            <div className="reach-box-section">
              <button
                className={`support-btn ${activeButton === 1 ? "active" : ""}`}
                onClick={() => handleButtonClick(1, "Talk to Doctor")}
              >
                <div className="reach-box-1">
                  <img src={appTalkDoctor} alt="talk-doctor" />
                  <p>Talk to Doctor</p>
                </div>
              </button>
              <button
                className={`support-btn ${activeButton === 2 ? "active" : ""}`}
                onClick={() => handleButtonClick(2, "Conduct Test")}
              >
                <div className="reach-box-2">
                  <img src={appConductTest} alt="conduct-test" />
                  <p>Conduct Test</p>
                </div>
              </button>
            </div>
          </div>
          <div className="Reach-us-cover-section">
            <div className="reach-us-image-box">
              <img src={appDignosticsupport} alt="diagnostics" />
              <p>Diagnostic</p>
            </div>
            <div className="reach-box-section">
              <button
                className={`support-btn ${activeButton === 3 ? "active" : ""}`}
                onClick={() => handleButtonClick(3, "Pathology")}
              >
                <div className="reach-box-1">
                  <img src={appPathlogy} alt="pathlogy" />
                  <p>Pathology</p>
                </div>
              </button>
              <button
                className={`support-btn ${activeButton === 4 ? "active" : ""}`}
                onClick={() => handleButtonClick(4, "Radiology")}
              >
                <div className="reach-box-2">
                  <img src={appRadiologysupport} alt="Radiology" />
                  <p>Radiology</p>
                </div>
              </button>
            </div>
          </div>
          <div className="Reach-us-cover-section">
            <div className="reach-us-image-box">
              <img src={appKnowMore} alt="know-more" />
              <p>Know more about</p>
            </div>
            <div className="reach-box-section">
              <button
                className={`support-btn ${activeButton === 5 ? "active" : ""}`}
                onClick={() => handleButtonClick(5, "Hospital")}
              >
                <div className="reach-box-1">
                  <img src={hospitalsupport} alt="hospital-support" />
                  <p>Hospital</p>
                </div>
              </button>
              <button
                className={`support-btn ${activeButton === 6 ? "active" : ""}`}
                onClick={() => handleButtonClick(6, "Teleconsultation")}
              >
                <div className="reach-box-2">
                  <img src={teleconsultationsupport} alt="teleconsultation" />
                  <p>Teleconsultation</p>
                </div>
              </button>
            </div>
          </div>
          <div className="Reach-us-cover-section">
            <div className="reach-us-image-box">
              <img src={appAssistance} alt="Assistance" />
              <p>Assistance in</p>
            </div>
            <div className="reach-box-section">
              <button
                className={`support-btn ${activeButton === 7 ? "active" : ""}`}
                onClick={() => handleButtonClick(7, "Insurance Claim")}
              >
                <div className="reach-box-1">
                  <img src={appInsuranceClaimSupport} alt="claim-support" />
                  <p>Insurance Claim</p>
                </div>
              </button>
              <button
                className={`support-btn ${activeButton === 8 ? "active" : ""}`}
                onClick={() => handleButtonClick(8, "Others")}
              >
                <div className="reach-box-2">
                  <img src={appOtherSupport} alt="Others" />
                  <p>Others</p>
                </div>
              </button>
            </div>
          </div>
          <div className="reach-submit-btn">
            <button onClick={onSubmit}>
              <p>Submit</p>
            </button>
          </div>
        </Modal.Body>
        {successmodal && (
          <SuccessFull show={modalShow} successMessage={successMessage} />
        )}
      </Modal>
    </>
  );
};

export default Footer;
