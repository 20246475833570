import React, { useEffect, useState } from "react";
import SuccessFull from "../../Animation/SuccessFull";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { MY_ACCOUNT_API, REQUESTOPTIONS, WELLNESS_REQUEST_API } from "../../Constants";
import Loader from "../../Animation/Loader";

const FormWellnessPages = ({
  ImgData,
  bgColor,
  btnColor,
  borderColor,
  data,
}) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [messagebox, setMessageBox] = useState("");
  const [userinfodata, setUserInfoData] = useState([]);
  //   const [mobileNo, setMobileNo] = useState(userinfodata?.mobile || "");
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    const userData = async () => {
      try {
        const userDataRes = await fetch(`${MY_ACCOUNT_API}`, REQUESTOPTIONS)

        if (!userDataRes.ok) {
          console.error(
            "API error:",
            userDataRes.status,
            userDataRes.statusText
          );
          const errorData = await userDataRes.json().catch(() => null);
          console.error("Error details:", errorData);
          throw new Error("Network response was not ok");
        }
        const storeUserData = await userDataRes.json();
        setUserInfoData(storeUserData)
      } catch (error) {
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    }
    userData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfoData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        profile_details: {
          ...prevState.data.profile_details,
          [name]: value
        }
      }
    }));
  };


  const onSubmit = async () => {
    // e.preventDefault();
    console.log('onSubmit', userinfodata)

    if (
      !userinfodata.data?.profile_details?.fullname ||
      !userinfodata.data?.profile_details?.mobile ||
      !userinfodata.data?.profile_details?.email ||
      !messagebox
    ) {
      toast.error("All Fields are required.");
      return;
    }

    const formData = new FormData();
    formData.append("support_request_no", userinfodata.data?.profile_details?.mobile);
    formData.append("support_request_type", data);
    formData.append("name", userinfodata.data?.profile_details?.fullname);
    formData.append("mobile", userinfodata.data?.profile_details?.mobile);
    formData.append("email", userinfodata.data?.profile_details?.email);
    formData.append("message", messagebox);

    console.log("Form Data:", formData);

    try {
      const wellnessData = await fetch(WELLNESS_REQUEST_API, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      const responseData = await wellnessData.json();

      if (!wellnessData.ok) {
        console.error(
          "API error:",
          wellnessData.status,
          wellnessData.statusText
        );
        const errorData = await wellnessData.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      if (responseData.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(responseData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          navigate("/home");
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="wellness-wrapper-section">
      <div className="wellness-cover-box">
        <div className="wellness-image-box">
          <img src={ImgData} width="100%" alt="review" />
        </div>

        {loading && loading ? (
          <Loader />
        ) : (
          <>
            <div
              className="wellness-form-box"
              style={{ background: bgColor, border: borderColor }}
            >
              <label>Enter Your Name</label>
              <input
                type="text"
                name="fullname"
                placeholder="Enter your name"
                value={userinfodata.data?.profile_details?.fullname || ""}
                onChange={handleInputChange}
              />
              <label>Mobile Number</label>
              <input
                type="text"
                name="mobile"
                placeholder="Enter your number"
                maxLength={10}
                value={userinfodata.data?.profile_details?.mobile || ""}
                onChange={handleInputChange}
              />
              <label>Email Address</label>
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={userinfodata.data?.profile_details?.email || ""}
                onChange={handleInputChange}
              />
              <label>Enter Your Message</label>
              <textarea
                name="message"
                id
                cols={30}
                rows={3}
                placeholder="Enter Your Message"
                value={messagebox}
                onChange={(e) => setMessageBox(e.target.value)}
              />
            </div>
            <div className="wellness-form-submit fitness-submit">
              <button
                type="submit"
                style={{ background: btnColor }}
                onClick={() => {
                  onSubmit();
                  setModalShow(true);
                }}
              >
                Submit
              </button>
            </div>
          </>
        )}

      </div>
      {successmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} />
      )}
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 1000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </div>
  );
};

export default FormWellnessPages;
