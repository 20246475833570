import React from 'react'
import WellnessHeader from './WellnessHeader'
import { Form } from 'react-router-dom'
import FormWellnessPages from './FormWellnessPages'
import { appLifeStyleFormImg } from '../../images'

const LifeStyle = () => {
  return (
    <>
    <div className="home-review-banner">
        <WellnessHeader data="Life Style" />
        <FormWellnessPages data="Life Style" ImgData={appLifeStyleFormImg} bgColor="#DEF6E5 0% 0% no-repeat padding-box" btnColor="#45B665 0% 0% no-repeat padding-box" borderColor="1px solid #92E3A9"/>
    </div>
    </>
  )
}

export default LifeStyle