import { React, useContext, useEffect, useState } from "react";
import ProfileHeader from "../Components/Header";
import {
  ArrowBack,
  appCart,
  appNotification,
  headerLogo,
  appWalletprofile,
  appShapeProfile,
  appMedicineProfile,
} from "../../../images";
import { ArrowBackButton } from "../../../utils/AllFunction";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../ContextApi";
import Loader from '../../../Animation/Loader';
import NoDataFoundComp from "../../../components/NoDataFound";
import { REQUESTOPTIONS, NO_PACKAGE_GIF , MY_ACCOUNT_API } from "../../../Constants";

const MyWalletUtilization = () => {
  const [myPackageData, setmyPackageData] = useState(false);
  const [isPackageDataloading, setPackageDataLoading] = useState(true);
  const [myWalletTransactionData, setmyWalletTransactionData] = useState([]);

  const token = localStorage.getItem("ACCESS_TOKEN");
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  useEffect(() => {
    const FetchPackageData = async () => {
      setPackageDataLoading(true);
      try {
        const response = await fetch(
          `${MY_ACCOUNT_API}`,REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          setmyPackageData(res?.data?.my_package_data[0]);
          setmyWalletTransactionData(
            res?.data?.wallet_data?.wallet_transaction
          );
          console.log(res?.data?.wallet_data?.wallet_transaction);
          setPackageDataLoading(false);
        } else {
          setPackageDataLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    FetchPackageData();
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>My Wallet Utilization</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink>
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
            <div className="cart-icon">
              <NavLink to="/emptycart">
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
            </div>
          </div>
        </div>
        {isPackageDataloading ? (
          <Loader/>
        ) : (
          <>
            <div className="my-wallet-utilization-main-wrapper-cover">
              <>
                <div className="wallet-points-box">
                  <div className="wallet-points-box-cover">
                    <div className="wallet-point-heading">
                      <p>Available Points</p>
                      <h6>{myWalletTransactionData[0]?.points}</h6>
                    </div>
                    <div className="wallet-point-image">
                      {/* <img src={appWalletprofile} width="100%" alt="review" /> */}
                    </div>
                  </div>
                </div>
                <div className="wallet-categories-section">
                  <div className="wallet-categories-main-box-wrapper">
                    {myWalletTransactionData.length > 0 ? (
                      myWalletTransactionData.map((walletData) => (
                        <div
                          className="wallet-categories-box-wrapper"
                          key={walletData.id}
                        >
                          <div className="wallet-categories-images">
                            <div className="wallet-background-cricle">
                              {/* <img
                                src={appShapeProfile}
                                width="100%"
                                alt="review"
                              /> */}
                            </div>
                            <div className="wallet-background-medicine">
                              {/* <img
                                src={appMedicineProfile}
                                width="100%"
                                alt="review"
                              /> */}
                            </div>
                          </div>
                          <div className="wallet-categories-content-box">
                            <p>
                              Category :{" "}
                              <span className="medi">
                                {walletData.module_id == 1
                                  ? "DIAGNOSTICS"
                                  : walletData.module_id == 2
                                  ? "DOCTOR"
                                  : walletData.module_id == 3
                                  ? "TELECONSULTATION"
                                  : walletData.module_id == 4
                                  ? "NUTRITION"
                                  : walletData.module_id == 5
                                  ? "MEDICINE"
                                  : "DENTAL"}
                              </span>
                            </p>
                            <p>
                              Points Used :{" "}
                              <span className="point">
                                {walletData?.points}
                              </span>
                            </p>
                            <p>
                              Date of Transaction :{" "}
                              <span>{walletData?.crdt_dt}</span>
                            </p>
                            {walletData.remarks && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: walletData.remarks,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFoundComp/>
                    )}
                  </div>
                </div>
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyWalletUtilization;
