import { React, useEffect, useState, useContext } from "react";
import {
  ArrowBack,
  appArrowBack,
  appAvatarDefault,
  appHealthRecord,
  appLogout,
  appOrderDetails,
  appPackageDetail,
  appPrivacyPolicy,
  appRateUs,
  appTermCondition,
  appWalletIcon,
} from "../../images";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import { fetchApi } from "../../utils/AllFunction";

import { getToken, removeToken } from "../../utils/TokenUtils";
import { MY_ACCOUNT_API } from "../../Constants";

const Profile = (props) => {
  const [profileData, setProfileData] = useState([]);
  const [isprofiledataLoaded, setisProfiledataLoaded] = useState(true);
  const [isErrorData , setErrorData] = useState([])

  const [token, setToken] = useState(getToken());
  const navigate = useNavigate();

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };


  useEffect(() =>{
    fetchApi(MY_ACCOUNT_API, requestOptions, setProfileData, setisProfiledataLoaded, setErrorData);
  },[])

  const handleLogout = () => {
    removeToken();
    setToken(null);
    clearData();
    navigate("/");

    const newToken = getToken();
    if (!newToken) {
      navigate("/");
    }
  };
  const clearData = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("medicineCartData");
    localStorage.removeItem("medicinedatastore");
    localStorage.removeItem("CartData");
    localStorage.removeItem("MedicineDetails");
    localStorage.removeItem("SelectedAddresses");
    localStorage.removeItem("pincode");
    localStorage.removeItem("medicineindexdata");
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("showDiscountedPrice");
    localStorage.removeItem("count_undefined");
    localStorage.removeItem("pincodeStatus");
    localStorage.removeItem("pincodeData");
    localStorage.removeItem("cartPrice");
    localStorage.removeItem("modalData");
    localStorage.removeItem("productCounts");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("SELECTED_TEST");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("timeoutId")
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="profile-banner">
          <div className="back-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="prescription-heading">
            <h5>Profile</h5>
          </div>
        </div>

        <div className="user-main-cover">
          <div className="user-cover-banner">
            <div className="user-banner-cont-box">
              <div className="user-banner-conts-box">
                <div className="user-image-section">
                  <img src={appAvatarDefault} width="100%" alt="Avatar" />
                </div>
                {isprofiledataLoaded ? (
                  <Loader />
                ) : (
                  <div className="user-detail-box-sec">
                    <h4>{profileData.data?.profile_details?.fullname}</h4>
                    <p>{profileData.data?.profile_details?.mobile}</p>
                    <p>{profileData.data?.profile_details?.email}</p>
                  </div>
                )}

                <div className="user-edit-wrap">
                  <a href="#">
                    <p>Edit</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="user-cover-menu-bar">
            <div className="user-cover-list-bar">
              <NavLink to="/my-package-details">
                <div className="user-cover-list-img-box">
                  <img
                    src={appPackageDetail}
                    width="100%"
                    alt="Package-Detail"
                  />
                  <p>My Package Details</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
            <div className="user-cover-list-bar">
              <NavLink to="/my-wallet-utilization">
                <div className="user-cover-list-img-box">
                  <img src={appWalletIcon} width="100%" alt="wallet" />
                  <p>My Wallet Utilization</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
            <div className="user-cover-list-bar">
              <NavLink to="/my-order-details">
                <div className="user-cover-list-img-box">
                  <img src={appOrderDetails} width="100%" alt="order-detail" />
                  <p>My Order Details</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
            {/* <div className="user-cover-list-bar">
              <a href="#">
                <div className="user-cover-list-img-box">
                  <img src={appHealthRecord} width="100%" alt="Health-record" />
                  <p>My Health Record</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </a>
            </div> */}
            <div className="user-cover-list-bar">
              <NavLink to="/terms-conditions">
                <div className="user-cover-list-img-box">
                  <img
                    src={appTermCondition}
                    width="100%"
                    alt="term-conditon"
                  />
                  <p>Terms &amp; Condition</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
            <div className="user-cover-list-bar">
              <NavLink to="/privacy-policy">
                <div className="user-cover-list-img-box">
                  <img
                    src={appPrivacyPolicy}
                    width="100%"
                    alt="Privacy-policy"
                  />
                  <p>Privacy Policy</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
            <div className="user-cover-list-bar">
              <a href="#">
                <div className="user-cover-list-img-box">
                  <img src={appRateUs} width="100%" alt="rate" />
                  <p>Rate Us 5 stars</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </a>
            </div>

            <div className="user-cover-list-bar">
              <NavLink to="/" onClick={() => handleLogout()}>
                <div className="user-cover-list-img-box">
                  <img src={appLogout} width="100%" alt="logout" />
                  <p>Logout</p>
                </div>
                <div className="user-list-arrow-icon">
                  <img src={appArrowBack} width="100%" alt="Back-arrow" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
