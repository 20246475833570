import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ArrowBackButton,
  handleNavigateSearch,
  fetchApi,
} from "../../utils/AllFunction";
import { AppContext } from "../../ContextApi";
import Loader from "../../Animation/Loader";
import NoDataFoundComp from "../../components/NoDataFound";
import { REQUESTOPTIONS , CATEGORY_TEST_API } from "../../Constants";
import GotoCart from "../Cart/GotoCart";

import {
  ArrowBack,
  appCartIcon,
  appHeartIcon,
  appLifeStyleIcon,
} from "../../images";
import Modals from "../Modals";
import {IoIosSearch } from "react-icons/io";

const CategoriesPage = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [apiData, setApiData] = useState([]);
  const { count } = useContext(AppContext);
  const [isDataLoading, setDataLoading] = useState(true);
  const [isErrorData, setErrorData] = useState([]);
  const navigate = useNavigate();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );

  useEffect(() => {
    fetchApi(CATEGORY_TEST_API, REQUESTOPTIONS, setApiData, setDataLoading, setErrorData);
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <div className="lab-book-categories">
          <div className="app-header-wraper">
            <div className="header-navigation-icon">
              <NavLink onClick={ArrowBackButton}>
                <img src={ArrowBack} width="100%" alt="arrow-back" />
              </NavLink>
            </div>
            <div className="header-top-section">
              <div className="header-top-section-category-section">
                <h3>Select Test by Categories</h3>
                <Modals show={modalShow} onHide={() => setModalShow(false)} />
              </div>
              <div className="header-top-section-add-to-cart-section">
                <button className="">
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  <span className="Header-count-number">{CartData.length}</span>
                </button>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          </div>

          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input
                type="text"
                placeholder="Search"
                onClick={() => handleNavigateSearch(navigate)}
              />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="Test-categories-content-wrapper-box">
            <div className="container">
              <div className="row d-flex justify-content-center">
                {isDataLoading ? (
                  <Loader />
                ) : apiData.data.length > 0 ? (
                  apiData.data.map((test) => (
                    <div
                      className="col-lg-5 col-md-5 col-5 book-categories-repo"
                      key={test.cat_id}
                    >
                      <NavLink
                        to={`/test-by-categories/${test.cat_id}/${test.cat_name}`}
                      >
                        <div className="lab-book-categories-box">
                          <img
                            
                            src={`${test.cart_icon}`}
                            width="100%"
                            alt="review"
                          />
                          <p>{test.cat_name}</p>
                        </div>
                      </NavLink>
                    </div>
                  ))
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </div>
          </div>
          {CartData.length > 0 && <GotoCart link="diagnostictest" CartData={CartData} />}
        </div>
      </div>
    </>
  );
};

export default CategoriesPage;
