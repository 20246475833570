import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { PAY_AT_HOME_API  } from "../../Constants";
import { formatDate } from "../../utils/AllFunction";
import { Toaster } from "react-hot-toast";
import { useNavigate , Link} from "react-router-dom";

const PreviewBooking = ({
  showPreviewBooking,
  dentalBookingDate,
  userInfo,
  selectedTimeSlot,
  handlePreviewBooking,
  response_insert_id,
  requestOptions,
  setPaymentAnimation,
  setPaymentLoading,
  setPaymentModal,
  setpaymentMsg
}) => {

  const navigate = useNavigate();


  const handleDentalBooking = () => {
    
    const formdata = new FormData();
    formdata.append("insert_id" , response_insert_id.toString())

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = formdata;
    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

    async function submitDentalConsultationBooking() {
      try {
        const response = await fetch(PAY_AT_HOME_API, requestOptionsCopy);
        const res = await response.json();
        if (res.status === 200) {
          setPaymentAnimation(true);
          setpaymentMsg(res.message);
          setPaymentLoading(false);   
          setPaymentModal(true);
          setTimeout(() => {
            setPaymentModal(false);
            navigate("/home");
          }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
    }
    submitDentalConsultationBooking();
  };

  return (
    <>
      <div className="preview-confirmation-details-cover-wrapper">
        <Modal
          show={showPreviewBooking}
          onHide={handlePreviewBooking}
          className="book-now-modal-main-wrapper"
        >
          <Modal.Header closeButton className="book-now-header">
            <Modal.Title>
              <h6>Preview confirmation Details</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="book-now-modal-body">
            <div className="preview-card-cover">
              <li>
                <p>Name</p>
                <span>:{userInfo.fullname}</span>
              </li>
              <li>
                <p>Mobile Number</p> <span>:{userInfo.mobile}</span>
              </li>
              <li>
                <p>Appointment Date</p>{" "}
                <span>:{formatDate(dentalBookingDate)}</span>
              </li>
              <li>
                <p>Appointment Time</p> <span>:{selectedTimeSlot}</span>
              </li>
              <div className="appointment-btn-box">
                <button
                  className="pay-at-center-bt"
                  onClick={() => handleDentalBooking()}
                >
                  Pay at Center
                </button>
                <button className="cancel-btn" >
                    <Link to="/home">Cancel</Link>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />  
      </div>
    </>
  );
};

export default PreviewBooking;
