import { React, useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { appCartIcon, headerLogo ,ArrowBack } from "../../../images";
import {
  handleNavigate,
  handleNavigateSearch,
  fetchApi,
} from "../../../utils/AllFunction";
import { IoIosSearch } from "react-icons/io";
import { AppContext } from "../../../ContextApi";
import SpecialityProduct from "../Components/SpecialityProduct";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import { ArrowBackButton } from "../../../utils/AllFunction";

import { TELECONSULTATION_LIST_API , REQUESTOPTIONS } from "../../../Constants";


const SpecialistTeleConsultation = (props) => {
  const navigate = useNavigate();
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [isErrorData, setErrorData] = useState([]);
  const [teleconsultationData, setTeleConsultationData] = useState([]);
  const [isDataLoading, setDataLoading] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchquery, setSearchQuery] = useState("");

  const token = localStorage.getItem("ACCESS_TOKEN");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props.token ?? token}`,
    },
  };

  useEffect(() => {
    fetchApi(
      TELECONSULTATION_LIST_API,
      requestOptions,
      setTeleConsultationData,
      setDataLoading,
      setErrorData
    );
  }, []);

  const handleSearch = (e, teleconsultationData) => {
    const searchquery = e.target.value.toLowerCase();

    if (teleconsultationData?.tele_departments?.teleconsultaton.length > 0) {
      const searchedData =
        teleconsultationData?.tele_departments?.teleconsultaton.filter(
          (teleData) =>
            teleData.department_name.toLowerCase().includes(searchquery)
        );

      setFilteredData(searchedData);
      setSearchQuery(searchquery);
    } else {
      setFilteredData(null);
    }
  };



  const handleBooking = (data , type) =>{
    localStorage.setItem('BOOKED_TELECONSULTATION_DATA' , JSON.stringify(data));
    localStorage.setItem("mbConsultType" , JSON.stringify("speciality"))
    if(type !== ""){
      navigate(`/book-teleconsultation?type=${type}`)
    }
    else{
      navigate("/book-teleconsultation");
    }
  }

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-logo mx-3">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Teleconsultation</h6>
            </div>
          </div>
        </div>
        <div className="app-searh-section">
          <div className="inputWithIcon input-tele-search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e, teleconsultationData)}
            />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="home-teleconsultation-main-wrapper mt-4 px-2">
          <div className="specialist-doctor-home-wrapper">
            <div className="specialist-header d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Specialist Doctors</h6>
            </div>
            <div className="specialist-product-main-wrapper mt-3 mb-5 px-1">
              {isDataLoading ? (
                <Loader />
              ) : (
                <>
                  {searchquery.length > 0 ? (
                    <div className="specialist-product-box d-flex justify-content-between">
                      {filteredData && filteredData.length > 0 ? (
                        filteredData.map((specialityData) => (
                          <SpecialityProduct
                            key={specialityData.id}
                            data={specialityData}
                            imgPath={teleconsultationData?.img_path}
                            handleBooking={() =>handleBooking(specialityData , specialityData.is_free)}
                          />
                        ))
                      ) : (
                        <NoDataFoundComp />
                      )}
                    </div>
                  ) : (
                    <div className="specialist-product-box d-flex justify-content-between">
                      {teleconsultationData?.tele_departments?.teleconsultaton
                        ?.length > 0 ? (
                        teleconsultationData?.tele_departments?.teleconsultaton.map(
                          (specialityData) => (
                            <SpecialityProduct
                              key={specialityData.id}
                              data={specialityData}
                              imgPath={teleconsultationData?.img_path}
                              handleBooking={() =>handleBooking(specialityData , specialityData.is_free)}
                            />
                          )
                        )
                      ) : (
                        <NoDataFoundComp />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialistTeleConsultation;
