import React from 'react'
import Lottie from "lottie-react";
import MediLoader from "../AnimationJson/medi-loader.json";

const Mediloader = () => {
  return (
    <>
    <Lottie animationData={MediLoader} className='medi-loader-data'/>
    </>
  )
}

export default Mediloader;