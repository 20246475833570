import React, { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ArrowBackButton } from "../utils/AllFunction";
import { UPLOAD_PRESCRIPTION__API } from "../Constants";
import {
  ArrowBack,
  PrescriptionImg,
  appHeartIcon,
  appPrescriptionImg,
} from "../images";
import { AppContext } from "../ContextApi";
import { set } from "react-hook-form";
import SuccessFull from "../Animation/SuccessFull";
import { Button, Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

const UploadPrescription = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { count } = useContext(AppContext);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [uploadedImages, setUploadedImages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    const imageFilePreviews = [];

    for (let i = 0; i < imageFiles.length; i++) {
      const file = imageFiles[i];
      const previewUrl = URL.createObjectURL(file);
      imageFilePreviews.push({ file, previewUrl });
    }

    setUploadedImages((prevImages) => [...prevImages, ...imageFilePreviews]);
  };

  const handleImageDelete = (index) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1);
    setUploadedImages(newImages);
  };

  const handleSubmitFile = async () => {
    if (uploadedImages.length === 0) {
      return;
    }

    const uploadedImage = uploadedImages[0]?.file;

    if (uploadedImage && id === "1") {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        if (id === "1") {
          localStorage.setItem("uploadedImages", base64String);
          toast.success("Upload Prescription Successfully");
          setTimeout(() => {
            navigate("/placeorder");
          }, 2000);
        }
      };

      reader.readAsDataURL(uploadedImage);
      return;
    }

    const formdata = new FormData();
    uploadedImages.forEach((uploadedImage, index) => {
      formdata.append(`file[${index}]`, uploadedImage.file);
    });

    try {
      const fileRes = await fetch(UPLOAD_PRESCRIPTION__API
        ,
        {
          headers: {
            Authorization: `${token}`,
          },
          method: "POST",
          body: formdata,
        }
      );

      if (!fileRes.ok) {
        console.error("API error:", fileRes.status, fileRes.statusText);
        const fileData = await fileRes.json().catch(() => null);
        console.error("Error details:", fileData);
        throw new Error("Network response was not ok");
      }

      const uploadData = await fileRes.json();
      setFileData(uploadData);
      // console.log(uploadData);

      if (uploadData.status === 200) {
        setSuccessModal(true);
        setSuccessMessage(uploadData.message);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setSuccessModal(false);
          navigate("/home");
        }, 3000);
      } else {
        alert("Unsuccessful submission");
      }
    } catch (error) {
      console.error("Error fetching home data:", error);
    }
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="prescription-main-box-cover">
            <div className="app-header-wraper">
              <div className="header-navigation-icon">
                <NavLink onClick={ArrowBackButton}>
                  <img src={ArrowBack} width="100%" alt="arrow-back" />
                </NavLink>
              </div>
              <div className="header-top-section">
                <div className="header-top-section-category-section">
                  <h3>Upload Prescription</h3>
                  <p>Please upload prescription for any help</p>
                </div>
                <div className="header-top-section-add-to-cart-section-hert">
                  <img src={appHeartIcon} width="100%" alt="heart-icon" />
                </div>
              </div>
            </div>
            <div className="prescription-image-box">
              <img src={appPrescriptionImg} width="100%" alt="Prescriotion" />
            </div>
            <div className="prescription-upload">
              <input
                type="file"
                name="file-input"
                id="file-input"
                label="Upload Prescription"
                onChange={handleImageUpload}
                accept="image/*"
                className="file-upload"
              />
              <label className="upload-file" for="file-input">
                <span>Upload Prescription</span>
              </label>
            </div>
            <div className="valid-prescription" onClick={openModal}>
              <p>What is Valid Prescription?</p>
            </div>
            <Modal centered show={isModalVisible} onHide={closeModal}>
              <Modal.Header className="valid-head" closeButton>
                <Modal.Title className="valid-presc">
                  What is Valid Prescription ?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="valid-body">
                <img src={PrescriptionImg} alt="Prescripition-image" />
              </Modal.Body>
              <Modal.Footer className="valid-foot">
                <p>
                  Prescription should contain details of doctor and patient's clinic
                  visit date,medicines will be given as per prescription, with
                  supported file types like jpeg,jpg,png and pdf
                </p>
              </Modal.Footer>
            </Modal>
            <div className="add-prescription-box">
              {uploadedImages.map((uploadedImage, index) => (
                <div className="add-box-pre" key={index}>
                  <div className="add-box">
                    <img
                      src={uploadedImage.previewUrl}
                      width="100%"
                      alt={`Preview ${index + 1}`}
                    />
                  </div>
                  <div className="delete-box">
                    <button
                      className="delete-btn"
                      onClick={() => handleImageDelete(index)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="submit-button">
              {uploadedImages.length > 0 && (
                <button
                  onClick={() => {
                    handleSubmitFile();
                  }}
                >
                  <p>Submit</p>
                </button>
              )}
            </div>
        </div>

        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "custom-toast",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
              fontSize: "14px",
              width: "300px",
              top: "55px !important",
            },
          }}
        />
      </div>
      {successmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} />
      )}

      {/* ... existing code ... */}
    </>
  );
};

export default UploadPrescription;
