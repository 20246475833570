import React, { useEffect , useState } from "react";
import ProfileHeader from "../Components/Header";
import Loader from '../../../Animation/Loader';
import { REQUESTOPTIONS , TERMS_CONDITION_API } from "../../../Constants";

const PrivacyPolicy = () => {
    const [privacy_policy_htmlContent , setPrivacy_policy_htmlContent] = useState([])
    const [isContentLoading , setisContentLoading] = useState(true)

    const token = localStorage.getItem("ACCESS_TOKEN");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    };

  useEffect(() => {
    setisContentLoading(true)
    const FetchTermsHtmlContent = async () => {
      try {
        const response = await fetch(
          `${TERMS_CONDITION_API}`,REQUESTOPTIONS);
        const res = await response.json();
        // console.log(res)
        if (res) {
            setPrivacy_policy_htmlContent(res.html_content)
            setisContentLoading(false)
        } else {
          console.log(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    FetchTermsHtmlContent();
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <ProfileHeader Heading="Privacy Policy" />
        <div className="terms-condition-main-wrapper-cover">
        {
            privacy_policy_htmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: privacy_policy_htmlContent }} />
            ) : <Loader/>
        }
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
