// SuccessFull.js

import React from 'react';
import { ComingSoonGif } from '../images';

import Modal from 'react-bootstrap/Modal';

const ComingSoon = () => {
    
    return (
        <div className="coming-soon-main-cover-box">
                    <img src={ComingSoonGif} alt="coming-soon"></img>
                </div>
    )
}

export default ComingSoon;
