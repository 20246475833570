import React from 'react'
import WellnessHeader from './WellnessHeader'
import FormWellnessPages from './FormWellnessPages'
import { appWeightlossFormImg } from '../../images'

const Weight = () => {
    return (
        <>
            <div className="home-review-banner">

                <WellnessHeader data={"Weight Loss"} />
                <FormWellnessPages data="Weight Loss" ImgData={appWeightlossFormImg} bgColor="#E7F3FE 0% 0% no-repeat padding-box" btnColor="#1F88FE 0% 0% no-repeat padding-box" borderColor="1px solid #1F88FE" />
            </div>
        </>
    )
}

export default Weight