import React, { useEffect, useState } from 'react'
import { ArrowBack, appCart, appHealthRightArrow, appNotification, headerLogo } from '../../images'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Animation/Loader'
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS , TP_GET_IMBURSEMENTDATA_API , SAVE_TP_IMBURSEDATA } from '../../Constants';
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO, format } from 'date-fns';
import useQuery from "../../CustomHooks/useQuery"
import { PiUploadBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { Toaster, toast } from "react-hot-toast";


const ReimbursementForm = (props) => {
    const package_code = useQuery('package_code');
    const package_id = useQuery('package_id');
    const module_id = useQuery('module_id');
    const title = useQuery('title');
    const description = useQuery('description');
    const [imburseData , setImburseData] = useState([]);
    const navigate = useNavigate();

    const token = localStorage.getItem("ACCESS_TOKEN");
    const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `${props.token ?? token}`,
        },
      };
    

    const [loadingHomeData, setLoadingHomeData] = useState(true);
    const [homedata, setHomeData] = useState(null);
    const [userinfo, setUserInfo] = useState([]);
    const [dob , setDob] = useState([]);
    const [files, setFiles] = useState({
        invoice: [],
        prescription: [],
        bankdetails: [],
        otherdocuments: [],
      });

      const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        formState: { errors },
      } = useForm();

       const documentTypes = Object.keys(files);
       const filesData = [];

       const reimburseUserName = watch('name');
       const reimburseAmount = watch('amount');

      const docTypes = [
        {
            invoice: "Invoice / Receipt",
            prescription: "Prescription",
            bankdetails: "Bank Details",
          otherdocuments: "Other Documents",
        },
      ];

      useEffect(() => {
        const formData = new FormData();
        formData.append('user_package_id' , package_id);
        formData.append('module_id' , module_id);
        formData.append('package_code' , package_code);


        const requestOptionsCopy = { ...requestOptions };
        requestOptionsCopy.method = "POST";
        requestOptionsCopy.body = formData;
        requestOptionsCopy.processData = false;
        requestOptionsCopy.contentType = false;  
    
        async function getTpImburseData() {
          try {
            const response = await fetch(
              `${TP_GET_IMBURSEMENTDATA_API}`,
              requestOptionsCopy
            );
            const res = await response.json();
            setImburseData(res)
          } catch (error) {
            console.error(error);
          }
        }
        getTpImburseData();
      }, []);

    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const homeDataRes = await fetch(HOME_DASHBOARD_SCREEN_API, requestOptions);

                if (!homeDataRes.ok) {
                    console.error('API error:', homeDataRes.status, homeDataRes.statusText);
                    const errorData = await homeDataRes.json().catch(() => null);
                    console.error('Error details:', errorData);
                    throw new Error('Network response was not ok');
                }

                const homeData = await homeDataRes.json();
                setHomeData(homeData);
                setUserInfo(homeData?.user_info);
            } catch (error) {
                console.error("Error fetching home data:", error);
            } finally {
                setLoadingHomeData(false);
            }
        }
        fetchDataUser();
    }, []);

    useEffect(() =>{
        setValue('email' , imburseData?.email);
        setValue('mobile_number' , imburseData?.mobile);
        setValue('package_code' , package_code);
        setValue('lead_type' , module_id);
        setValue('user_package_id' , package_id);

        // setValue('amount' , imburseData?.pending_amount);
    },[imburseData])

    useEffect(() => {
        Object.entries(files).forEach(([documentType, fileInputs]) => {
          if (fileInputs.length > 0) {
            fileInputs.forEach((file, index) => {
              const reader = new FileReader();
    
              reader.onload = () => {
                const documentData = {
                  file_type : file.type , 
                  file_name: documentType,
                  file_path: reader.result,
                };
                filesData.push(documentData);
                if (documentType == Object.keys(files)[Object.keys(files).length - 1] && index == fileInputs.length - 1) {
                    setValue('files', filesData);
                }
              };
    
              reader.readAsDataURL(file);
            });
          }
        });
        setValue('files' ,filesData)
        
      }, [files]);

    const formatDate = (date) => {
        if (!date) return '';
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to 'yyyy-MM-dd'
        setDob(formattedDate)
        return formattedDate;
    };

    const handleNameChange = (e) =>{
        const UserName = e.target.value ;
        const SelectUser = imburseData?.family_list.find((family_member) => family_member.name == UserName);
        const selectedGender = imburseData?.gender_list.find((gender) => gender?.text == SelectUser?.gender);

        setValue('name' ,UserName);
        setDob(SelectUser?.date_of_birth);
        setValue('gender' , selectedGender?.text);
        setValue('family_user_id' , SelectUser?.id);
        setValue('relation' , SelectUser?.relation);
        setValue('dob' , SelectUser?.date_of_birth);

    }

    const handleFileChange = (e, documentType) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => {
          // Filter out files with the same name
          const uniqueNewFiles = newFiles.filter(
            (newFile) =>
              !prevFiles[documentType].find(
                (prevFile) => prevFile.name === newFile.name
              )
          );
          return {
            ...prevFiles,
            [documentType]: [...prevFiles[documentType], ...uniqueNewFiles],
          };
        });
      };

    const handleFileDelete = (e, documentType, file, index) => {
        e.preventDefault();
        const updatedDoc = files[documentType].filter(
          (myfile) => myfile.name !== file.name
        );
        setFiles((prevFiles) => ({
          ...prevFiles,
          [documentType]: updatedDoc,
        }));
    };

    
    const ReimburseMentSubmission = (data) =>{
        data.files = filesData
        if(files?.invoice?.length == 0 ){
            toast.error('Please Upload Invoice / Receipt')
        }
        else if(files?.prescription?.length == 0){
            toast.error('Please Upload Prescription')
        }
        else if(files?.bankdetails?.length == 0){
            toast.error('Please Upload Bank Details')
        }
        else{
            const requestOptionsCopy = { ...REQUESTOPTIONS };
            requestOptionsCopy.method = "POST";
            requestOptionsCopy.body = JSON.stringify(data);

            async function saveTpImburseData() {
                try {
                    const response = await fetch(SAVE_TP_IMBURSEDATA,requestOptionsCopy);
                    const res = await response.json();
                    if (res.status) {
                        toast.success(res.message);
                        setTimeout(() =>{
                            window.history.back();
                        },2000)
                    } else {
                        toast.error(res.message);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            saveTpImburseData()
        }
    }
    

    return (
        <>
            <div className="home-review-banner">
                <div className="home-app-header">
                    <div className="header-logo">
                        <img src={headerLogo} className='mr-3' width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p className='ml-3'>{title}</p>
                        </div>
                    </div>
                </div>

                <div className='opd-reimbursement-main-wrapper-cover'>
                    <div className="book-now-form-main-cover">
                        <div className='reimburse-header-title p-3'>
                            <h5>{title}</h5>
                            <p>{description}</p>
                        </div>

                        <div className="reimbursement-main-form-cover p-4 pt-0 pb-0">
                            <form onSubmit={handleSubmit(ReimburseMentSubmission)}>
                                <div className='reimburse-header-form'>
                                    <Form.Group
                                        className="mb-3 book-now-input-box"
                                        controlId="exampleForm.ControlInput1"
                                        >
                                        <Form.Control
                                            type="text"
                                            placeholder="Mobile Number"
                                            {...register("mobile_number", {
                                            required: "Please enter your number",
                                            })}
                                            maxLength={10}
                                            disabled
                                        />
                                        <span className="text-danger validation-error">
                                            {errors.mobile_number && errors.mobile_number.message}
                                        </span>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3 book-now-input-box"
                                        controlId="exampleForm.ControlInput1"
                                        >
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Email Address"
                                            {...register("email", {
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Please enter a valid email address",
                                            },
                                            })}
                                            onChange={(e) => {
                                                setValue('email' , e.target.value);
                                            }}
                                            disabled
                                        />
                                        <span className="text-danger validation-error">
                                            {errors.email && errors.email.message}
                                        </span>
                                    </Form.Group>
                                </div>                      
                                <h5 className='text-center mb-2 reimburseTitle'>Reimbursement Details</h5>

                                <div className="reimbursement-main-form">
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            <div className='reimbursement-input-box'>
                                                <select
                                                    name="name"
                                                    id="gender"
                                                    className="gender-input"
                                                    {...register("name")}
                                                    onChange={(e) =>{
                                                        handleNameChange(e)
                                                    }}

                                                >
                                                    <option value="">Select Name</option>
                                                    {imburseData?.family_list?.map((optionData) =>{
                                                        return (
                                                            <option value={optionData?.name}>{optionData?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div className='reimbursement-input-box'>
                                                <select
                                                    name="gender"
                                                    id="gender"
                                                    className="gender-input"
                                                    {...register("gender")}
                                                    onChange={(e) => setValue('gender' , e.target.value)}
                                                >
                                                    
                                                    {imburseData?.gender_list?.map((optionData , index) =>{
                                                        return (
                                                            <option value={optionData?.text}>{optionData?.text}{index == 0 && ' gender'}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div className='reimbursement-input-box'>
                                                <select
                                                    name="relation"
                                                    id="gender"
                                                    className="gender-input"
                                                    {...register("relation")}
                                                    onChange={(e) => setValue('relation' , e.target.value)}
                                                >
                                                    <option>Select relation</option>
                                                    {imburseData?.relation_list?.map((optionData) =>{
                                                        return (
                                                            <option value={optionData?.value}>{optionData?.text}</option>
                                                        )
                                                    })}
                                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-0'>
                                            <div className='reimbursement-input-box'>
                                            <DatePicker
                                                name="start_date"
                                                value={dob}
                                                onChange={(date) => formatDate(date)}
                                                placeholderText="Enter Date of Birth"
                                                showYearDropdown={false}
                                                showMonthDropdown={false}
                                                autoComplete="off"
                                                className="mb-3 booking-date"
                                                minDate={new Date(imburseData?.min_date_time)}
                                                maxDate={new Date(imburseData?.max_date_time)}
                                                dateFormat="yyyy-MM-dd" 
                                             
                                            />
                                            </div>
                                        </div>
                                        <div className='col-lg-12 mb-3'>
                                            <Form.Group
                                                className="mb-3 book-now-input-box"
                                                controlId="exampleForm.ControlInput1"
                                                >
                                            
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Amount to be reimburse"
                                                    {...register("amount")}
                                                    onChange={(e) => {
                                                        setValue('amount' , e.target.value);
                                                    }}
                                                />
                                                <span className="text-danger validation-error mt-2">
                                                    Pending Amount {imburseData?.pending_amount}
                                                </span>
                                                <br></br>
                                                <span className="text-danger validation-error mt-2">
                                                    {reimburseAmount > imburseData?.pending_amount && imburseData?.pending_amount_validation }
                                                </span>
                                            </Form.Group>
                                        </div>

                                        <div className="supporting-documents-main-cover-wrapper">
                                            <div className="supporting-documents-content-cover">
                                                <h5 className='document-header-title'>Supporting Documents</h5>
                                                <div className='document-upload-main-cover'>
                                                       {documentTypes.map((documentType, ind) => (
                                                            <div className="document-upload-box-cover">
                                                                <div className='document-header-box '>
                                                                    <h6>{docTypes[0][documentType]} {ind + 1 == documentTypes.length ? "" : "*"}</h6>
                                                                    <div className="upload-document-btn-box">
                                                                        <button>
                                                                        <span>
                                                                            <PiUploadBold />
                                                                        </span>
                                                                        <input
                                                                            type="file"
                                                                            name="claim_upload_file[]"
                                                                            onChange={(e) =>
                                                                            handleFileChange(e, documentType)
                                                                            }
                                                                            style={{
                                                                            opacity: 0,
                                                                            width: 100,
                                                                            position: "absolute",
                                                                            left: "-8px",
                                                                            top: 3,
                                                                            }}
                                                                            multiple
                                                                            // required={ind + 1 != documentTypes.length}
                                                                        />
                                                                        Upload
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='document-file-cover'>
                                                                    {files[documentType].length > 0 && (
                                                                        files[documentType].map((file, index) => (
                                                                        <div className="document-file-name-box d-flex justify-content-between align-items-center">
                                                                            <div className='document-label'>
                                                                                <h6>{file.name}</h6>
                                                                            </div>
                                                                            <div className="delete-doc-btn">
                                                                                                            <button
                                                                                                            onClick={(e) =>
                                                                                                                handleFileDelete(
                                                                                                                e,
                                                                                                                documentType,
                                                                                                                file,
                                                                                                                index
                                                                                                                )
                                                                                                            }>
                                                                                                                <IoClose/>
                                                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))} 
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${reimburseUserName == "" || reimburseAmount == ""  || reimburseAmount > imburseData?.pending_amount ? 'reimbursement-submit-box submit-btn-box disable-submit-btn' : 'reimbursement-submit-box submit-btn-box' }`}>
                                            <button type='submit'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* <button onClick={handleFileCHeck}>Submit</button> */}
                        </div>  
                    </div>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                        className: "custom-toast",
                        duration: 2000,
                        style: {
                            background: "#fff",
                            color: "#363636",
                            fontSize: "14px",
                            width: "300px",
                            top: "55px !important",
                        },
                        }}
                    />
                </div>

            </div>
        </>
    )
}

export default ReimbursementForm