import React from 'react'
import { ArrowBackButton } from '../../utils/AllFunction'
import { ArrowBack, appCart, appFitness, appFitnessFormImg, appNotification, headerLogo } from '../../images'
import { NavLink } from 'react-router-dom'
import WellnessHeader from './WellnessHeader'
import FormWellnessPages from './FormWellnessPages'

const Fitness = () => {
    
    return (
        <div>
            <div className="home-review-banner">
                <WellnessHeader data="Fitness" />
                <FormWellnessPages data="Fitness" ImgData={appFitnessFormImg} bgColor="#FFDDE6 0% 0% no-repeat padding-box" btnColor="#DE5B7C 0% 0% no-repeat padding-box" borderColor="1px solid #DE5B7C"/>

            </div>
        </div>
    )
}

export default Fitness