import { React, useContext, useEffect, useState } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import ProfileHeader from "../Components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import { REQUESTOPTIONS, MY_ACCOUNT_API } from "../../../Constants";
import { ArrowBack, appCart, appNotification , appLabCart , appEmptyCart} from "../../../images";
import { ArrowBackButton } from "../../../utils/AllFunction";
import { NavLink } from "react-router-dom";
import "swiper/css";

const MyOrderDetails = () => {
  const [isPackageDataloading, setPackageDataLoading] = useState(true);
  const [orderDetailsData, setorderDetailsData] = useState([]);
  const [teleConsultationData, setTeleConsultationData] = useState([]);
  const [NoTeleConsultationData, setNoTeleConsultationData] = useState(false);
  const [isNoOrderDetailsdata, setNoOrderDetails] = useState(false);
  const [medicinesOrdersData, setmedicinesOrdersData] = useState([]);
  const [key, setKey] = useState("first");

  useEffect(() => {
    const FetchPackageData = async () => {
      setPackageDataLoading(true);
      try {
        const response = await fetch(`${MY_ACCOUNT_API}`, REQUESTOPTIONS);
        const res = await response.json();
        if (res.status) {
          res.data?.order_details?.length > 0
            ? setorderDetailsData(res?.data?.order_details[0])
            : setNoOrderDetails(true);
            console.log(res.data.doctor_details)

          res.data.doctor_details.length > 0
            ? setTeleConsultationData(res?.data?.doctor_details)
            : setNoTeleConsultationData(true);

          setmedicinesOrdersData(res?.data?.order_medicine);
          setPackageDataLoading(false);
          
        } else {
          setPackageDataLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    FetchPackageData();
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-navigation-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="header-logo">
            <div className="user-name">
              <p>My Order Details</p>
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink>
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
            <div className="cart-icon">
              <NavLink to="/emptycart">
                <img src={appCart} width="100%" alt="review" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="tab-main-cover-wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
            <Row>
              <Col lg={12} className="mb-2 tab-cover">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={2}
                  navigation
                  pagination={{ clickable: true }}
                >
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onSelect={() => setKey("first")}
                        >
                          Medicine Order
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onSelect={() => setKey("second")}
                        >
                          Lab Tests
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          onSelect={() => setKey("third")}
                        >
                          Teleconsultation
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fourth"
                          onSelect={() => setKey("fourth")}
                        >
                          Doctor Appointments
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  {/* Add more SwiperSlides for additional tabs if needed */}
                </Swiper>
              </Col>
              <Col lg={12}>
                {isPackageDataloading ? (
                  <Loader />
                ) : (
                  <>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="lab-test-main-wrapper-cover">
                          <div className="medicine-main-cover-wrapper">
                            {medicinesOrdersData.length > 0 ? (
                              medicinesOrdersData.map((medicineData) => {

                                {/* {console.log(medicineData)}   */}
                                return (
                                  <div className="medicine-card-cover">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="medicine-img-box">
                                          <img
                                            src={
                                              medicineData?.order_items[0]
                                                ?.image
                                            }
                                            alt="medicine-img-box"
                                          ></img>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="medicine-details-box">
                                          <h6>
                                            {
                                              medicineData?.order_items[0]
                                                ?.medicine_name
                                            }
                                          </h6>
                                          <h6 className="order_id">
                                            Order ID :{" "}
                                            <span>
                                              {medicineData?.order_id}
                                            </span>
                                          </h6>
                                          <ul>
                                            <li>
                                              Quantity :{" "}
                                              <span>
                                                {
                                                  medicineData?.order_items[0]
                                                    ?.quantity
                                                }
                                              </span>
                                            </li>
                                            <li>
                                              Payment :{" "}
                                              <span>
                                                {
                                                  medicineData?.razorpay_responce
                                                }
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="medicines-empty-cart-cover">
                                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                  <div className="empty-cart-image">
                                    <img
                                      src={appEmptyCart}
                                      width="100%"
                                      alt="EmptyCart"
                                    />
                                  </div>
                                  <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                    <p>Your Medicine Cart is empty</p>
                                    <NavLink to="/buymedicines">
                                      <button>
                                        <p>ADD MEDICINES</p>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <hr className="underline"></hr> */}
                            {!isNoOrderDetailsdata && (
                              <Accordion
                                defaultActiveKey="0"
                                flush
                                className="accordion-product-wrapper"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    View
                                    <span className="mx-1 product-count-box">
                                      {medicinesOrdersData.length > 0 &&
                                        medicinesOrdersData?.map(
                                          (data) => data.order_items.length - 1
                                        )}
                                    </span>
                                    more product(s)
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="medicine-inner-product-wrapper">
                                      {medicinesOrdersData.length > 0 &&
                                        medicinesOrdersData.map(
                                          (medicineData) =>
                                            medicineData?.order_items
                                              .slice(1)
                                              .map((orderedMedicine) => {
                                                return (
                                                  <div className="medicine-inner-product">
                                                    <div className="row">
                                                      <div className="col-3">
                                                        <div className="medicine-inner-product-img">
                                                          <img
                                                            src={
                                                              orderedMedicine?.image
                                                            }
                                                            alt="ordered-medicine"
                                                          ></img>
                                                        </div>
                                                      </div>
                                                      <div className="col-9">
                                                        <div className="medicine-inner-product-details">
                                                          <h6>
                                                            {
                                                              orderedMedicine?.medicine_name
                                                            }
                                                          </h6>
                                                          <h6>
                                                            Quantity :{" "}
                                                            {
                                                              orderedMedicine?.quantity
                                                            }
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })
                                        )}
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="teleconsultation-main-wrapper-cover">
                          <div className="labtest-card labtest-bg">
                            {orderDetailsData.length > 0 ? (
                              <ul>
                                <li>
                                  Patient :{" "}
                                  <span>{orderDetailsData?.patient_name}</span>(
                                  <span className="age">
                                    {orderDetailsData?.patient_age}
                                  </span>
                                  /
                                  <span>
                                    {orderDetailsData?.patient_gender}
                                  </span>
                                  )
                                </li>
                                <li>{orderDetailsData?.testname}</li>
                                <li>
                                  Appointment Date :{" "}
                                  <span>{orderDetailsData?.visit_date}</span>
                                </li>
                                <li>
                                  Appointment Time :{" "}
                                  <span>{orderDetailsData?.visit_time}</span>
                                </li>
                                <li>
                                  Booked on :{" "}
                                  <span>{orderDetailsData?.created_on}</span>
                                </li>
                                <li>
                                  Fees : ₹<span>{orderDetailsData?.cost}</span>
                                </li>
                              </ul>
                            ) : (
                              <div className="medicines-empty-cart-cover">
                                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                  <div className="empty-cart-image">
                                    <img
                                      src={appLabCart}
                                      width="100%"
                                      alt="EmptyCart"
                                    />
                                  </div>
                                  <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                    <p>Your Cart is empty</p>
                                    <NavLink to="/diagnostictest">
                                      <button>
                                        <p>ADD TESTS</p>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        {NoTeleConsultationData ? (
                          <NoDataFoundComp />
                        ) : (
                          <div className="teleconsultation-main-wrapper-cover">
                            {teleConsultationData?.length > 0 && teleConsultationData?.map((booking_data,ind) =>{
                              return (
                                <div className="labtest-card mb-3" key={ind}>
                                  <ul>
                                    <li>
                                      Patient :{" "}
                                      <span>
                                        {booking_data?.patient_name}
                                      </span>
                                      (
                                      <span className="age">
                                        {booking_data?.patient_age}
                                      </span>
                                      /
                                      <span>
                                        {booking_data?.patient_gender}
                                      </span>
                                      )
                                    </li>
                                    <li>{booking_data?.department_name}</li>
                                    <li>
                                      Appointment Date :{" "}
                                      <span>
                                        {booking_data?.appointment_date}
                                      </span>
                                    </li>
                                    <li>
                                      Booked on :{" "}
                                      <span>
                                        {booking_data?.bookedDate}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )
                            })}
                            
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="teleconsultation-main-wrapper-cover">
                          <h5>No Data Found</h5>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </>
                )}
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MyOrderDetails;
